// @flow
import type { Dispatch as DispatchRedux } from "redux";
import type { ReportType } from "./reportTemplates/model";
import initReportTemplatesState from "./reportTemplates/reportTemplates.initState";
import initCostCorrectionState from "./costCorrection/initState";
import type { State as ReportTemplatesState } from "./reportTemplates/types";
import type { State as CostCorrectionState } from "./costCorrection/types";
import type { Actions as CostCorrectionActions } from "./costCorrection/actions";

export type CommonState = { reportTemplates: ReportTemplatesState, costCorrection :CostCorrectionState };

export default (reportType: ReportType): CommonState => ({
  reportTemplates: { ...initReportTemplatesState, reportType },
  costCorrection: { ...initCostCorrectionState },
});

export type StoreType = {
  reportTemplates: ReportTemplatesState,
  costCorrection: CostCorrectionState,
}

export type Actions = CostCorrectionActions | Object;

export type Dispatch = DispatchRedux<Actions>
