// @flow
import {
  AppBar, Box, IconButton, Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import type { TransactionData } from "../../store/costCorrection/types";

export default (
  { transactionData, onClose }: { transactionData: TransactionData, onClose: () => void }
) => (
  <AppBar position="relative" color="primary">
    <Box p={3} display="flex">

      <Box flexGrow={1}>
        <Typography variant="body1" color="inherit">EDIT TRANSACTION</Typography>
        <Typography variant="subtitle2">
          TDS_CID:
          {transactionData.tds_cid}
        </Typography>
        <Typography variant="subtitle2">
          DATA2:
          {transactionData.data2}
        </Typography>
      </Box>
      <Box>
        <IconButton onClick={onClose} color="inherit" size="large">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  </AppBar>
);
