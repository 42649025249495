// @flow
// eslint-disable-next-line no-unused-vars
import React from "react";
import { connect } from "react-redux";
import type { TransactionData, State as CostCorrectionState } from "../../store/costCorrection/types";
import { closeModal, updateTransactionDataSaga } from "../../store/costCorrection/actions";
import CostCorrectionForm from "../CostCorrectionForm";

type OwnProps = $ReadOnly<{|
  checkedList: Array<number>
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  open: boolean,
  isLoading: boolean,
  transactionData: TransactionData,
  declineReasonValues: Array<string>,
  modalMode: string,

  onClose: () => void,
  onSave: (transactionData: {
    payout?: number,
    revenue?: number,
    isDeclined: boolean,
    declineReason: Array<string>,
  }) => void,
  isCostCorrectionProcessing: boolean,
|}>;

const mapStateToProps = (state: { costCorrection: CostCorrectionState }) => ({
  open: state.costCorrection.isOpenModal,
  modalMode: state.costCorrection.modalMode,
  isLoading: state.costCorrection.isLoading,
  transactionData: state.costCorrection.transactionData,
  declineReasonValues: state.costCorrection.dictionary.declineReason,
  isCostCorrectionProcessing: state.costCorrection.isCostCorrectionProcessing,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeModal()),
  onSave: (transactionData) => dispatch(updateTransactionDataSaga(transactionData)),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(CostCorrectionForm);
