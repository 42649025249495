// @flow

import Filter from "./Filter.types";
import type { Params } from "../types";

class FilterGroup {
  title: string;

  filters: Array<Filter>;

  constructor(title: string, filters: Array<Filter>) {
    this.title = title;
    this.filters = filters;
  }

  getFilters(): { [string]: Filter } {
    return this.filters.reduce((filters, filter: Filter) => ({ ...filters, [filter.filterKey]: filter }), {});
  }

  includes(filterKey: string) {
    return this.filters.findIndex(({ filterKey: _k }) => _k === filterKey) !== -1;
  }

  find(cb: (a: Filter) => boolean): Filter {
    return this.filters.find(cb) || new Filter();
  }

  getGroupNames(params: Params) {
    return {
      value: this.title,
      tooltipText: "These attributes are unavailable because you selected either hourly grouping, current day statistics or added tds_cid.",
      disabledCount: this.getDisabledFilters(params).length,
    };
  }

  getDisabledFilters(params: Params): Filter[] {
    return this.filters.filter((rule) => rule.disabled(params));
  }
}

export default FilterGroup;
