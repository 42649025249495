// @flow
/* eslint-disable import/max-dependencies */
import React, { Component } from "react";
import {
  Grid, Box, CircularProgress, ThemeProvider, StyledEngineProvider,
} from "@mui/material";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import environment from "environment";
import { Error } from "@fas/ui-core";
import { reportsTheme as theme } from "@fas/ui-themes";
import AppHeader from "../AppHeader";
import ReportPage from "../../pages/ReportPage";
import IndexPage from "../../pages/IndexPage";
import { userApiService } from "../../services/userApi";
import "typeface-roboto";

type Props = {};

type State = {
  loading: boolean
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    userApiService.getUserInfo(environment.endpoints.get.apiKey)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          window.location = `${environment.baseUrl}?r=${window.location.href}`;
        }
      });
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        {loading && (
          <Grid container justifyContent="center">
            <Box mt={40}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && (
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <AppHeader />
                <Routes>
                  <Route path="/" element={<IndexPage />} />
                  <Route path="/managersReport/:id" element={<ReportPage reportTypePage="manager" aclRule="/managersReport" />} />

                  <Route path="/managersReport" element={<ReportPage reportTypePage="manager" aclRule="/managersReport" />} />

                  <Route path="/accountantsReport/:id" element={<ReportPage reportTypePage="accountant" aclRule="/accountantsReport" />} />

                  <Route path="/accountantsReport" element={<ReportPage reportTypePage="accountant" aclRule="/accountantsReport" />} />

                  <Route path="/404" element={<Error />} />

                  <Route element={<Navigate to="/404" />} />
                </Routes>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        )}
      </>
    );
  }
}

export default App;
