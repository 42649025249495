// @flow
import React from "react";
import { TablePagination } from "@mui/material";
import TablePaginationActions from "../ReportTable/TablePaginationActions";

export const PAGE_SIZE_OPTIONS = [25, 50, 100, 1000];

interface Props {
  count: number;
  page: number;
  pageSize: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (pageSize: number) => void;
  rowsPerPageOptions?: number[];
}

const ReportTableFooter = ({
  pageSize,
  count,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
}: Props) => (
  <div>
    <TablePagination
      component="div"
      page={page}
      count={count}
      rowsPerPage={pageSize}
      onPageChange={onChangePage}
      onRowsPerPageChange={({ target: { value } }) => onChangeRowsPerPage(parseInt(value, 10))}
      rowsPerPageOptions={rowsPerPageOptions}
      ActionsComponent={TablePaginationActions}
    />
  </div>
);

ReportTableFooter.defaultProps = {
  rowsPerPageOptions: PAGE_SIZE_OPTIONS,
};

export default ReportTableFooter;
