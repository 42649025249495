import { put, select, takeEvery } from "redux-saga/effects";
import { REPORT_TEMPLATE_TOGGLE_FILTER_ALL_SAGA } from "../reportTemplates.actions-types";
import { selectCurrentReportModel, selectCurrent } from "../reportTemplates.selectors";
import { RESET_EXCLUDE_FILTERS } from "../reportTemplates.constants";
import { toggleFiltersSaga } from "../reportTemplates.actions";
import Report from "../model/Report";

export function* toggleAllFilters(action) {
  const current = yield select((state) => selectCurrent(state.reportTemplates));
  const report: Report = yield select((state) => selectCurrentReportModel(state.reportTemplates));
  const { params } = current;
  const { value } = action;

  const filtersKeys = report
    .getAllowedFilterKeys(params)
    .filter((x) => !RESET_EXCLUDE_FILTERS.includes(x));

  yield put(toggleFiltersSaga({ filters: filtersKeys, value }));
}

export function* watchToggleAllFilters() {
  yield takeEvery(REPORT_TEMPLATE_TOGGLE_FILTER_ALL_SAGA, toggleAllFilters);
}
