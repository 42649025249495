// @flow
import initState from "./initState";
import type { State } from "./types";
import type { Actions } from "./actions";
import {
  COST_CORRECTION_CLOSE_MODAL,
  COST_CORRECTION_OPEN_MODAL,
  COST_CORRECTION_SET_MODAL_VALUES,
  COST_CORRECTION_SET_TRANSACTION_DATA,
  COST_CORRECTION_START_LOADING,
  COST_CORRECTION_END_LOADING,
  CHANGE_MODAL_MODE,
  SET_IS_COST_CORRECTION_PROCESSING,
} from "./actions-types";

export default (state: State = initState, action: Actions): State => {
  switch (action.type) {
    case CHANGE_MODAL_MODE: {
      return {
        ...state,
        modalMode: action.value,
      };
    }
    case COST_CORRECTION_OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      };
    }
    case COST_CORRECTION_CLOSE_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      };
    }
    case COST_CORRECTION_START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case COST_CORRECTION_END_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case COST_CORRECTION_SET_MODAL_VALUES: {
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          ...action.value,
        },
      };
    }
    case COST_CORRECTION_SET_TRANSACTION_DATA: {
      return {
        ...state,
        transactionData: action.value,
      };
    }
    case SET_IS_COST_CORRECTION_PROCESSING: {
      return {
        ...state,
        isCostCorrectionProcessing: action.value,
      };
    }
    default:
      return state;
  }
};
