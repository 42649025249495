// @flow
import FilterGroup from "../SelectionGroupFactory/FilterGroup.types";
import type { Filters, Params } from "../types";
import type { FilterGroupsConfig, FiltersModel } from "./types/index.types";
import { getFilterModel } from "../SelectionGroupFactory";

export type GroupNamesListType = {
  value: string,
  tooltipText?: string,
  disabledCount?: number,
};

const getFilterGroups = (config: FilterGroupsConfig) => Object.keys(config)
  .map((title) => new FilterGroup(title, config[title].map(getFilterModel)));

export default class ReportFilterGroup {
  filterGroups: FilterGroup[];

  filters: FiltersModel;

  constructor(filterGroupsConfig: FilterGroupsConfig) {
    this.filterGroups = getFilterGroups(filterGroupsConfig);
    this.filters = this.filterGroups
      .reduce((filters, filterGroup: FilterGroup) => Object.assign(filters, filterGroup.getFilters()), {});
  }

  getFilters(): FiltersModel {
    return this.filters;
  }

  getGroupTitlesByFilters(selectedFilters: Filters): string[] {
    const selectedFilterKeys = Object.keys(selectedFilters);
    const selectedGroup = (group: FilterGroup) => selectedFilterKeys
      .some((filterKey) => group.includes(filterKey));

    return this.filterGroups.filter(selectedGroup).map<string>(({ title }) => title);
  }

  getGroupNamesList(params: Params): GroupNamesListType[] {
    return this.filterGroups.map<GroupNamesListType>((group) => group.getGroupNames(params));
  }
}
