// @flow
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { userService } from "../../services/user";

function IndexComponent() {
  return (
    <Box p={2} display="flex" alignItems="flex-start" justifyContent="space-around">
      <Card variant="outlined" p={1} sx={{ width: "350px", textAlign: "center" }}>
        <CardContent>
          {userService.can(["/managersReport"]) && (
            <Box mb={1}>
              <Link to="/managersReport">Admin reports for managers</Link>
            </Box>
          )}
          {userService.can(["/accountantsReport"]) && (
            <Box mb={1}>
              <Link to="/accountantsReport">Admin reports for accountants</Link>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default IndexComponent;
