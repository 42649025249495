// @flow
import React, { Component } from "react";
import Button from "@mui/material/Button";
import {
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import { SelectPaper } from "@fas/ui-core";
import autobind from "react-autobind";
import { ClickAwayListener } from "@mui/material";
import type { Item, List } from "./types/List";

interface Props {
  list?: List,
  onSelect: (Item) => void;
  onAddNew: () => void;
  onSearchTemplate: (string) => void;
  classes: any,
}

interface State {
  searchString: string;
  opened: boolean;
}

const styles = (theme) => ({
  root: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.borderColor}`,
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
    },
  },
  wrapper: {
    margin: `0 ${theme.spacing(2)} 0 0`,
  },
  scroller: {
    overflowY: "auto",
    maxHeight: "300px",
  },
  paper: {
    position: "absolute",
    transform: "translateY(10px)",
    width: "400px",
    zIndex: 100,
    padding: theme.spacing(2),
  },
  listItem: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    borderRadius: 0,
    textDecoration: "underline",
    color: theme.palette.text.linkColor,
    justifyContent: "flex-start",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ccc",
    },
  },
});

class TemplateSelect extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      searchString: "",
      opened: false,
    };
    autobind(this, "onSearchStringChange", "toggle");
  }

  onSearchStringChange(searchString: string) {
    const { onSearchTemplate } = this.props;
    onSearchTemplate(searchString);
    this.setState({ searchString });
  }

  toggle() {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  }

  renderSelectPaper() {
    const { searchString } = this.state;
    const {
      onAddNew, onSelect, list,
    } = this.props;
    return (
      <SelectPaper
        searchStringPlaceholder="Search template"
        searchStringDefaultValue={searchString}
        onSearchStringChange={this.onSearchStringChange}
        onAddNew={onAddNew}
        addButtonText="New Template"
        onSelect={onSelect}
        // $FlowFixMe
        list={list || []}
      />
    );
  }

  render() {
    const { opened } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Button
          className={classes.root}
          onClick={this.toggle}
          variant="contained"
          disableElevation
          data-testid="templatesButton"
        >
          <FilterListIcon />
          <span>Template</span>
        </Button>
        {opened && (
          <ClickAwayListener onClickAway={this.toggle}>
            {this.renderSelectPaper()}
          </ClickAwayListener>
        )}
      </div>
    );
  }
}

export default React.memo<any>(withStyles(styles)(TemplateSelect));
