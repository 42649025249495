import {
  call, debounce, put, select,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { setTemplatesList } from "../reportTemplates.actions";
import { UPDATE_TEMPLATES_LIST_SAGA } from "../reportTemplates.actions-types";
import { MESSAGES } from "../reportTemplates.constants";

export function* updateTemplateList(action) {
  try {
    yield put({ type: "START_TEMPLATES_LIST" });
    const searchStr = action.payload?.searchStr || "";
    const reportType = yield select((state) => state.reportTemplates.reportType);

    const list = yield call(api.getTemplatesList, {
      searchStr,
      reportType,
    });

    yield put(setTemplatesList({ list }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || MESSAGES.ERROR_TEMPLATES_LIST, severity: "error" }));
    yield put({ type: "ERROR_TEMPLATES_LIST", error });
  }
}

export function* watchUpdateTemplateList() {
  yield debounce(500, UPDATE_TEMPLATES_LIST_SAGA, updateTemplateList);
}
