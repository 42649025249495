// @flow
import type { State } from "./types";

const initState: State = {
  transactionData: {
    tds_cid: "",
    data2: "",
    payout: 0,
    revenue: 0,
    isDeclined: true,
    declineReason: [],
    date: "",
    conversions: 0,
    conversion_date: "",
    costValue: 0,
  },
  status: false,
  dictionary: {
    declineReason: [],
  },
  isOpenModal: false,
  modalMode: "correctionAll",
  isLoading: false,
  isCostCorrectionProcessing: false,
};

export default initState;
