// @flow
import {
  ClickAwayListener, Grow, Button, Paper, Popper, MenuItem, MenuList,
} from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  onSave: () => void;
  disableSave: boolean;
  disableUpdate: boolean,
  onUpdate: () => void,
  disabled: boolean,
  updateTooltip: string,
}

export default ({
  disabled,
  disableSave,
  onSave,
  disableUpdate,
  onUpdate,
  updateTooltip,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    setOpen(false);
    onUpdate();
  };
  const handleSave = () => {
    setOpen(false);
    onSave();
  };

  return (
    <>
      <Button
        ref={anchorRef}
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={handleToggle}
        data-testid="expandMenuButton"
      >
        Save template
        <ArrowDropDownIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <Tooltip title={updateTooltip}>
                    <span>
                      <MenuItem
                        data-testid="updateButton"
                        disabled={disableUpdate}
                        onClick={handleUpdate}
                      >
                        Update Current Template
                      </MenuItem>
                    </span>
                  </Tooltip>
                  <MenuItem
                    data-testid="saveButton"
                    disabled={disableSave}
                    onClick={handleSave}
                  >
                    Save New Template
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
