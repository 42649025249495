// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import BodyContainer from "../BodyContainer";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import { mapDispatchToProps, mapStateToProps } from "./store-connector";
import type { State, Template } from "../../store/reportTemplates/types";
import type { Hints, Toggle } from "../../store/reportTemplates/model/types/index.types";
import type { GroupBy } from "../../store/reportTemplates/reportTemplates.constants";

const useStyles = makeStyles((theme) => ({
  modal: {
    background: `${theme.palette.background.paper}`,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: `${theme.shape.borderRadius}`,
  },
}));

type OwnProps = {|
  onClose:() => void;
  onShowReport:() => void;
  onBakeReport:() => void;
|}

type Props = {|
  ...OwnProps,
  reportTemplates: State;
  toggleSwitcherSaga: ({ value: string, checked: boolean })=>void;
  setTemplateName: (value: string)=>void;
  saveTemplate: (asNewTemplate: boolean) => void;
  disableSave: boolean;
  disableUpdate: boolean;
  disableFooterAction: boolean;
  updateTooltip: string;
  setTemplateGroupBySaga: (value: GroupBy) => void;
  toggles: Toggle[];
  groupBySelection: Array<{ label: string, value: GroupBy }>,
  hints: Hints,
|}

const CreateReportModal = (props: Props) => {
  const {
    reportTemplates,
    toggleSwitcherSaga,
    onClose,
    setTemplateName,
    saveTemplate,
    onShowReport,
    onBakeReport,
    disableSave,
    disableUpdate,
    disableFooterAction,
    updateTooltip,
    setTemplateGroupBySaga,
    toggles,
    groupBySelection,
    hints,
  } = props;
  const { current } = reportTemplates;
  const {
    params, name,
  }: Template = current;

  const classes = useStyles();
  return (
    <div className={classes.modal} data-testid="templateModal">
      <Box component="div">
        <ModalHeader
          groupBySelection={groupBySelection}
          toggles={toggles}
          params={params}
          onClick={toggleSwitcherSaga}
          onClose={onClose}
          groupBy={params.groupBy}
          setGroupBy={setTemplateGroupBySaga}
          hints={hints}
        />
      </Box>
      <BodyContainer />
      <ModalFooter
        updateTooltip={updateTooltip}
        onChangeName={setTemplateName}
        templateName={name}
        onShow={onShowReport}
        onBake={onBakeReport}
        onSave={() => saveTemplate(true)}
        onUpdate={() => saveTemplate(false)}
        disabled={disableFooterAction}
        disableSave={disableSave}
        disableUpdate={disableUpdate}
      />
    </div>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(CreateReportModal);
