// @flow
import {
  addFilter,
  clearFilters,
  deleteFilter,
  toggleFilterSaga,
  reorderFilters,
  toggleFilterAllSaga,
  setHiddenFilters,
} from "../../store/reportTemplates/reportTemplates.actions";

import {
  selectCurrent,
  selectIsAllSelected,
  selectGroupedFilters,
  selectOrderedFilters,
  selectGroupNamesList,
} from "../../store/reportTemplates/reportTemplates.selectors";
import type { State } from "../../store/reportTemplates/types";

export const mapStateToProps = ({ reportTemplates }: { reportTemplates: State }) => ({
  reportTemplates,
  groups: selectGroupedFilters(reportTemplates),
  groupNamesList: selectGroupNamesList(reportTemplates),
  isAllSelected: selectIsAllSelected(reportTemplates),
  orderedFilters: selectOrderedFilters(reportTemplates),
  currentTemplate: selectCurrent(reportTemplates),
});

export const mapDispatchToProps = {
  addFilter,
  toggleAll: toggleFilterAllSaga,
  deleteFilter,
  toggleFilter: toggleFilterSaga,
  clearFilters,
  reorderFilters,
  setHiddenFilters,
};
