/* eslint-disable max-classes-per-file */
// @flow
import React from "react";
import dayjs from "dayjs";
import { ShowMoreLink } from "@fas/ui-core";
import {
  isLatinWithNumbers,
  isLessThan,
  isLatinOnly,
  isLatinWithSpecAddtlSymbolsWhitespace,
  isLatinNumbersSpecSymbols,
  isInteger,
  isPositiveInteger,
  isIn,
} from "../../../services/utils/validationHelper";
import { prepareSplitValues } from "../../../services/utils/utils";
import type { Params } from "../types";
import { GROUP_BY_LIST } from "../reportTemplates.constants";

/**
 * Filter is a base class for filter model
 * @namespace FilterModel
 */
export default class Filter {
  title: string;

  filterKey: string;

  /**
   * Is disabled select filter(second column)
   * @param {Params} params in current template
   * @returns {boolean} is disabled ?
   */
  // eslint-disable-next-line no-unused-vars
  disabled(params: Params) {
    return false;
  }

  /**
   * Is editable filter(third column)
   * @param {Params} params in current template
   * @returns {boolean} is editable filter ?
   */
  editable(): boolean {
    return true;
  }

  /**
   * With value filter
   * @param {Params} params in current template
   * @returns {boolean} with value ?
   */
  value(params: Params) {
    return !this.disabled(params);
  }

  /**
   * Prepare data to view(in table cell)
   * @param {Object} data row data
   * @param {Params} params in current template
   * @returns {string} view cell
   */
  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(2);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }

  /**
   * Prepare data to api
   * @param {Object} data row data
   * @param {Params} params in current template
   * @returns {Array<string | boolean | number>} filter data
   */
  // eslint-disable-next-line no-unused-vars
  prepareDataType(data: { [string]: any }, params: Params): Array<mixed> {
    return data[this.filterKey];
  }

  getTitle() {
    return this.title;
  }

  validate(value: string) {
    return !!value;
  }

  getErrorMsg() {
    return "Wrong value format";
  }

  // eslint-disable-next-line no-unused-vars
  getSortLabel(params: Params) {
    return this.filterKey;
  }

  // eslint-disable-next-line no-unused-vars
  getTitleWithGroupBy(params: Params) {
    return this.title;
  }

  getValue(value: string) {
    return [value];
  }

  /**
   * Get headers objects
   * @param {Params} params in current template
   * @returns {Array<{fieldName: string, name: string}>} headers objects this filter
   */
  // eslint-disable-next-line no-unused-vars
  getHeadersObject(params: Params) {
    return [
      {
        name: this.title,
        fieldName: this.filterKey,
      },
    ];
  }
}

export class WithLowerCaseFilter extends Filter {
  prepareDataType(value: { [string]: any }) {
    const ignoredValuesList: string[] = ["UNKNOWN"];
    return value[this.filterKey].map((val) => {
      if (ignoredValuesList.includes(val)) {
        return val;
      }
      return val.toLowerCase().trim();
    });
  }
}

// TODO: при валидации на беке добавить конвертацию date в conversion_date
export class Date extends Filter {
  title = "Date";

  filterKey = "date";

  editable(): boolean {
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  disabled(params: Params) {
    return true;
  }

  value(params: Params) {
    return !params.tdsCid;
  }

  prepareData(data: { [string]: any }, params: Params) {
    const { groupBy } = params;
    const from = dayjs(data.date_from).format("YYYY-MM-DD");
    const to = dayjs(data.date_to).format("YYYY-MM-DD");

    if (groupBy === GROUP_BY_LIST.day) {
      return `${from}`;
    }
    if (groupBy === GROUP_BY_LIST.hour) {
      const dateGroup = dayjs(data.date_group).format("YYYY-MM-DD HH:mm:ss");
      return `${dateGroup}`;
    }

    return `${from} - ${to}`;
  }

  prepareDataType(data: { [string]: any }, params: Params) {
    const { groupBy } = params;
    const [{ from, to }] = data[this.filterKey];

    if (groupBy === GROUP_BY_LIST.hour) {
      return [
        {
          from: dayjs(from).format("YYYY-MM-DD HH:mm:ss"),
          to: dayjs(to)
            .endOf("d")
            .format("YYYY-MM-DD HH:mm:ss.SSS"),
        },
      ];
    }

    return [
      {
        from: dayjs(from).format("YYYY-MM-DD"),
        to: dayjs(to).format("YYYY-MM-DD"),
      },
    ];
  }

  getSortLabel(params: Params) {
    if (params.groupBy === GROUP_BY_LIST.hour) {
      return "date_group";
    }
    return "date_from";
  }

  getTitleWithGroupBy(params: Params) {
    if (params.tdsCid) {
      return this.title;
    }
    return `${this.title} (Group by ${params.groupBy})`;
  }

  getHeadersObject(params: Params) {
    if (params.groupBy === GROUP_BY_LIST.hour) {
      return [
        {
          name: this.title,
          fieldName: "date_group",
        },
      ];
    }
    return [
      {
        name: `${this.title} from`,
        fieldName: `${this.filterKey}_from`,
      },
      {
        name: `${this.title} to`,
        fieldName: `${this.filterKey}_to`,
      },
    ];
  }
}

export class ConversionDate extends Filter {
  title = "Conversion Date";

  filterKey = "conversion_date";

  editable(): boolean {
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  disabled(params: Params) {
    return true;
  }

  value(params: Params) {
    return params.tdsCid;
  }

  prepareData(data: { [string]: any }, params: Params) {
    const { groupBy } = params;
    const from = dayjs(data.conversion_date_from).format("YYYY-MM-DD");
    const to = dayjs(data.conversion_date_to).format("YYYY-MM-DD");

    if (groupBy === GROUP_BY_LIST.day) {
      return `${from}`;
    }
    if (groupBy === GROUP_BY_LIST.hour) {
      const dateGroup = dayjs(data.conversion_date_group).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return `${dateGroup}`;
    }

    return `${from} - ${to}`;
  }

  prepareDataType(data: { [string]: any }, params: Params) {
    const { groupBy } = params;
    const [{ from, to }] = data[this.filterKey];

    if (groupBy === GROUP_BY_LIST.hour) {
      return [
        {
          from: dayjs(from).format("YYYY-MM-DD HH:mm:ss"),
          to: dayjs(to)
            .endOf("d")
            .format("YYYY-MM-DD HH:mm:ss.SSS"),
        },
      ];
    }

    return [
      {
        from: dayjs(from).format("YYYY-MM-DD"),
        to: dayjs(to).format("YYYY-MM-DD"),
      },
    ];
  }

  getSortLabel(params: Params) {
    if (params.groupBy === GROUP_BY_LIST.hour) {
      return "conversion_date_group";
    }
    return "conversion_date_from";
  }

  getTitleWithGroupBy(params: Params) {
    if (!params.tdsCid) {
      return this.title;
    }
    return `${this.title} (Group by ${params.groupBy})`;
  }

  getHeadersObject(params: Params) {
    if (params.groupBy === GROUP_BY_LIST.hour) {
      return [
        {
          name: this.title,
          fieldName: "conversion_date_group",
        },
      ];
    }
    return [
      {
        name: `${this.title}_from`,
        fieldName: `${this.filterKey}_from`,
      },
      {
        name: `${this.title}_to`,
        fieldName: `${this.filterKey}_to`,
      },
    ];
  }
}

export class ConversionTime extends Filter {
  title = "Conversion Time";

  filterKey = "conversion_time";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return !params.tdsCid;
  }
}

export class SmartLinkId extends WithLowerCaseFilter {
  title = "Smartlink ID";

  filterKey = "smartlink_campaign";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every(isLatinWithNumbers);
  }
}

export class SmartLinkName extends Filter {
  title = "Smartlink Name";

  filterKey = "smartlink_name";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class EntryReason extends WithLowerCaseFilter {
  title = "Entry Reason";

  filterKey = "entry_reason";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class SmartLinkSplitFlag extends Filter {
  title = "Smartlink Split Flag";

  filterKey = "smartlink_split";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class SmartLinkFunnelName extends Filter {
  title = "Smartlink Funnel Name";

  filterKey = "smartlink_funnel";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class SmartLinkFilter extends Filter {
  title = "Smartlink Filter";

  filterKey = "smartlink_filter";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class BasicId extends Filter {
  title = "Basic ID";

  filterKey = "campaign";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 512));
  }
}
export class BasicName extends Filter {
  title = "Basic Name";

  filterKey = "campaign_name";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class ReturnReason extends Filter {
  title = "Return Reason";

  filterKey = "return_reason";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class BasicSplitFlag extends Filter {
  title = "Basic Split Flag";

  filterKey = "split";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class BasicFunnelName extends Filter {
  title = "Basic Funnel Name";

  filterKey = "funnel";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class BasicFilter extends Filter {
  title = "Basic Filter";

  filterKey = "filter";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class CostType extends WithLowerCaseFilter {
  title = "Cost Type";

  filterKey = "cost_type";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class Country extends WithLowerCaseFilter {
  title = "Country";

  filterKey = "country";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 120));
  }
}
export class Region extends Filter {
  title = "Region";

  filterKey = "region";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class Platform extends WithLowerCaseFilter {
  title = "Platform";

  filterKey = "platform";

  validate(value: string) {
    return isLatinOnly(value);
  }
}
export class DeviceType extends Filter {
  title = "Device Type";

  filterKey = "device_type";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class DeviceBrand extends Filter {
  title = "Device Brand";

  filterKey = "device_vendor";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class DeviceModel extends Filter {
  title = "Device Model";

  filterKey = "device_model";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class DeviceOS extends Filter {
  title = "Device OS";

  filterKey = "device_os";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class DeviceOEM extends Filter {
  title = "Device OEM";

  filterKey = "device_oem";

  disabled(params: Params) {
    const hoursReportCase = (params.groupBy === GROUP_BY_LIST.hour && !params.currentDay);
    return hoursReportCase || params.currentDay;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class Browser extends Filter {
  title = "Browser";

  filterKey = "browser";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class BrowserLanguage extends Filter {
  title = "Browser Language";

  filterKey = "browser_language";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class Carrier extends Filter {
  title = "Carrier";

  filterKey = "carrier";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class ConnectionType extends Filter {
  title = "Connection type";

  filterKey = "connection_type";

  disabled(params: Params) {
    return params.currentDay || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class RiskScore extends Filter {
  title = "Risk score";

  filterKey = "risk_score";

  disabled(params: Params) {
    return !params.tdsCid;
  }

  validate(value: string) {
    return isInteger(value);
  }
}
export class TdsCid extends WithLowerCaseFilter {
  title = "tds_cid";

  filterKey = "tds_cid";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 255));
  }
}
export class Data2 extends Filter {
  title = "data2";

  filterKey = "partner_click";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 255));
  }
}
export class Ip extends Filter {
  title = "ip";

  filterKey = "ip";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 40);
  }
}
export class Age extends Filter {
  title = "age";

  filterKey = "age";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isPositiveInteger(value);
  }
}
export class EmailDomain extends Filter {
  title = "email_domain";

  filterKey = "email_domain";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class Pid extends Filter {
  title = "pid";

  filterKey = "profile_id";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 255));
  }
}
export class BaseCid extends Filter {
  title = "base cid";

  filterKey = "base_cids";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return !params.tdsCid;
  }
}

export class UtmCampaign extends Filter {
  title = "utm_campaign";

  filterKey = "utm_campaign";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 255));
  }
}

export class IDAff extends Filter {
  title = "ID Aff";

  filterKey = "utm_campaign";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 120));
  }
}

export class AffiliateType extends Filter {
  title = "Affiliate Type";

  filterKey = "utm_campaign_type";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 120));
  }
}

export class Name extends Filter {
  title = "Name";

  filterKey = "utm_campaign_name";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class Manager extends Filter {
  title = "Manager";

  filterKey = "utm_campaign_manager";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class AffiliateStatus extends Filter {
  title = "Affiliate Status";

  filterKey = "utm_campaign_status";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 120));
  }
}

export class PaymentMethod extends Filter {
  title = "Payment Method";

  filterKey = "payment_method";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 120));
  }
}

export class BeneficiaryName extends Filter {
  title = "Bank account name / Beneficiary";

  filterKey = "beneficiary_name";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class Wallet extends Filter {
  title = "Wallet";

  filterKey = "wallet";

  validate(value: string) {
    return isLessThan(value, 1024);
  }
}

export class BeneficiaryAddress extends Filter {
  title = "Beneficiary Address";

  filterKey = "beneficiary_address";

  validate(value: string) {
    return isLessThan(value, 1024);
  }
}

export class BeneficiaryCountry extends Filter {
  title = "Beneficiary Country";

  filterKey = "beneficiary_country";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class BankName extends Filter {
  title = "Bank Name";

  filterKey = "bank_name";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class BankAddress extends Filter {
  title = "Bank Address";

  filterKey = "bank_address";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class PayoutCountry extends Filter {
  title = "Payout Country";

  filterKey = "payout_country";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class PayoutCompany extends Filter {
  title = "Payout Company";

  filterKey = "payout_company";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class CreatedFirst extends Filter {
  title = "Created first";

  filterKey = "created_first";

  editable(): boolean {
    return false;
  }
}

export class CreatedLast extends Filter {
  title = "Created last";

  filterKey = "created_last";

  editable(): boolean {
    return false;
  }
}

export class FirstConversionDate extends Filter {
  title = "First conversion date";

  filterKey = "first_conversion_date";

  editable(): boolean {
    return false;
  }
}

export class UtmCampaignName extends Filter {
  title = "utm_campaign_name";

  filterKey = "utm_campaign_name";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class UtmCampaignManager extends Filter {
  title = "utm_campaign_manager";

  filterKey = "utm_campaign_manager";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class UtmContent extends Filter {
  title = "utm_content";

  filterKey = "utm_content";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 255));
  }
}
export class UtmTerm extends Filter {
  title = "utm_term";

  filterKey = "utm_term";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 255));
  }
}
export class UtmSource extends Filter {
  title = "utm_source";

  filterKey = "utm_source";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class IsPopunder extends Filter {
  title = "is_popunder";

  filterKey = "is_popunder";

  disabled(params: Params) {
    return params.tdsCid;
  }

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: "true",
      no: "false",
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}

export class S1 extends Filter {
  title = "s1";

  filterKey = "s1";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class S3 extends Filter {
  title = "s3";

  filterKey = "s3";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class S5 extends Filter {
  title = "s5";

  filterKey = "s5";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class ConversionType extends Filter {
  title = "Conversion Type";

  filterKey = "conversion_type";

  disabled(params: Params) {
    return !params.tdsCid;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class Currency extends Filter {
  title = "Currency";

  filterKey = "currency";

  disabled(params: Params) {
    return !params.tdsCid;
  }

  validate(value: string) {
    return isLatinNumbersSpecSymbols(value);
  }
}
export class P1 extends Filter {
  title = "p1";

  filterKey = "p1";

  disabled(params: Params) {
    return params.tdsCid;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class P2 extends Filter {
  title = "p2";

  filterKey = "p2";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class P3 extends Filter {
  title = "p3";

  filterKey = "p3";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class P4 extends Filter {
  title = "p4";

  filterKey = "p4";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class P5 extends Filter {
  title = "p5";

  filterKey = "p5";

  disabled(params: Params) {
    return params.tdsCid;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class P6 extends Filter {
  title = "p6";

  filterKey = "p6";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class P7 extends Filter {
  title = "p7";

  filterKey = "p7";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class P9 extends Filter {
  title = "p9";

  filterKey = "p9";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class P10 extends Filter {
  title = "p10";

  filterKey = "p10";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class S2 extends Filter {
  title = "s2";

  filterKey = "s2";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class JumpName extends Filter {
  title = "Jump Name";

  filterKey = "jump";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class JumpId extends Filter {
  title = "Jump ID";

  filterKey = "jump_key";

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 120));
  }
}
export class Brand extends Filter {
  title = "Brand(Offer Group)";

  filterKey = "tds_site_group";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class Site extends Filter {
  title = "Site";

  filterKey = "site";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class LP extends Filter {
  title = "LP(Offer)";

  filterKey = "landing_page";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class Domain extends Filter {
  title = "Domain";

  filterKey = "domain";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class CpaOfferConversionType extends Filter {
  title = "Offer Conversion Type";

  filterKey = "cpa_offer_conversion_type";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class ParentSplit extends Filter {
  title = "Parent Split";

  filterKey = "parent_split";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class ParentJump extends Filter {
  title = "Parent Jump";

  filterKey = "parent_jump";

  disabled(params: Params) {
    return (
      params.tdsCid
      || params.currentDay
      || params.groupBy === GROUP_BY_LIST.hour
    );
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class Account extends Filter {
  title = "Account";

  filterKey = "account";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class Partner extends Filter {
  title = "Partner";

  filterKey = "partner";

  validate(value: string) {
    return isLessThan(value, 120);
  }
}
export class LPTail extends Filter {
  title = "LP Tail";

  filterKey = "landing_page_tail";

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}
export class DeclineReason extends Filter {
  title = "Decline Reason";

  filterKey = "decline_reason";

  disabled(params: Params) {
    return !params.tdsCid;
  }

  validate(value: string) {
    return isLessThan(value, 120);
  }
}

export class CpaOfferID extends Filter {
  title = "Cpa Offer ID";

  filterKey = "cpa_offer_id";

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  getValue(value: string) {
    return prepareSplitValues(value);
  }

  validate(value: string) {
    return prepareSplitValues(value).every((v: string): boolean => isLessThan(v, 255));
  }
}
export class DeclineDate extends Filter {
  title = "Decline Date";

  filterKey = "decline_date";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}
export class CpaOfferName extends Filter {
  title = "Cpa Offer Name";

  filterKey = "cpa_offer";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class Department extends Filter {
  title = "Department";

  filterKey = "department";

  validate(value: string) {
    return isLessThan(value, 64);
  }
}

export class ManagerNameCPAOffer extends Filter {
  title = "Manager Name CPA Offer";

  filterKey = "manager_name";

  validate(value: string) {
    return isLessThan(value, 128);
  }
}

export class Vertical extends Filter {
  title = "Vertical";

  filterKey = "vertical";

  validate(value: string) {
    return isLessThan(value, 64);
  }
}

export class ExtNiche extends Filter {
  title = "Ext Niche";

  filterKey = "ext_niche";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class IsExt extends Filter {
  title = "Is Ext";

  filterKey = "is_ext";

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: "1",
      no: "0",
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}

export class IsActive extends Filter {
  title = "Is Active Offers";

  filterKey = "is_active";

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: "1",
      no: "0",
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}

export class IsValidOffers extends Filter {
  title = "Is Valid Offers";

  filterKey = "is_valid";

  disabled(params: Params) {
    return params.tdsCid;
  }

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: "1",
      no: "0",
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}

export class ProjectName extends Filter {
  title = "Project Name";

  filterKey = "project_name";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class AdvertiserManager extends Filter {
  title = "Advertiser Manager";

  filterKey = "advertiser_manager_name";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class PaymentModel extends Filter {
  title = "Payment Model";

  filterKey = "payment_model";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class ExtOfferGroup extends Filter {
  title = "Ext Offer Group";

  filterKey = "ext_offer_group";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class Postbacks extends Filter {
  title = "Postbacks";

  filterKey = "postbacks";

  disabled(params: Params) {
    return !params.tdsCid;
  }

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: "1",
      no: "0",
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}
export class IsDeclined extends Filter {
  title = "is_declined";

  filterKey = "is_declined";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: true,
      no: false,
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}
export class IsShaved extends Filter {
  title = "is_shaved";

  filterKey = "is_shaved";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: true,
      no: false,
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}

export class ParentTDSCampaign extends Filter {
  title = "Parent TDS campaign ";

  filterKey = "parent_tds_campaign";

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class SiteProvider extends Filter {
  title = "Site_provider";

  filterKey = "site_provider";

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLatinWithSpecAddtlSymbolsWhitespace(value);
  }
}

export class Entries extends Filter {
  title = "Entries";

  filterKey = "entries";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Impressions extends Filter {
  title = "Impressions";

  filterKey = "impressions";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Hits extends Filter {
  title = "Hits";

  filterKey = "hits";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Visits extends Filter {
  title = "Visits";

  filterKey = "jumps";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class BrandClicks extends Filter {
  title = "Brand Clicks";

  filterKey = "brand_clicks";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Clicks extends Filter {
  title = "Clicks";

  filterKey = "clicks";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class AltClicks extends Filter {
  title = "Alt_clicks";

  filterKey = "alt_clicks";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Fallbacks extends Filter {
  title = "Fallbacks";

  filterKey = "fallbacks";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class OriginalConversions extends Filter {
  title = "Original conversions";

  filterKey = "original_conversions";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Conversions extends Filter {
  title = "Conversions";

  filterKey = "conversions";

  editable(): boolean {
    return false;
  }
}

export class AltConversions extends Filter {
  title = "Alt Conversions";

  filterKey = "alt_conversions";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class Registrations extends Filter {
  title = "Registrations";

  filterKey = "registrations";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class CPAExtRegistrations extends Filter {
  title = "CPA Ext Registrations";

  filterKey = "cpa_ext_registrations";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class CPAExtPayments extends Filter {
  title = "CPA Ext Payments";

  filterKey = "cpa_ext_payments";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class Costs extends Filter {
  title = "Costs";

  filterKey = "cost";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class Cost extends Filter {
  title = "Cost";

  filterKey = "cost";

  editable(): boolean {
    return false;
  }
}

export class OriginalPayout extends Filter {
  title = "Original Payout";

  filterKey = "original_cost";

  editable(): boolean {
    return false;
  }
}
export class PayoutTNOffers extends Filter {
  title = "Payout TN offers";

  filterKey = "payout_tn_offers";

  editable(): boolean {
    return false;
  }
}
export class OriginalRevenue extends Filter {
  title = "Original Revenue";

  filterKey = "original_revenue";

  editable(): boolean {
    return false;
  }
}

export class CPMRevenue extends Filter {
  title = "Revenue with CPM";

  filterKey = "revenue_with_cpm";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class ExtCost extends Filter {
  title = "Ext_Cost";

  filterKey = "ext_cost";

  editable(): boolean {
    return false;
  }
}
export class IntCost extends Filter {
  title = "Int_Cost";

  filterKey = "int_cost";

  editable(): boolean {
    return false;
  }
}
export class Paid extends Filter {
  title = "Paid";

  filterKey = "paid";

  editable(): boolean {
    return false;
  }
}
export class ExtPaid extends Filter {
  title = "Ext_Paid";

  filterKey = "ext_paid";

  editable(): boolean {
    return false;
  }
}
export class IntPaid extends Filter {
  title = "Int_Paid";

  filterKey = "int_paid";

  editable(): boolean {
    return false;
  }
}
export class Declined extends Filter {
  title = "Declined";

  filterKey = "declined";

  editable(): boolean {
    return false;
  }
}
export class NotPaid extends Filter {
  title = "Not Paid";

  filterKey = "not_paid";

  editable(): boolean {
    return false;
  }
}
export class Adjustments extends Filter {
  title = "Adjustments";

  filterKey = "adjustments";

  editable(): boolean {
    return false;
  }
}
export class CostInCurrency extends Filter {
  title = "Cost In Currency";

  filterKey = "payout_in_currency";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return !params.tdsCid;
  }
}
export class Payout extends Filter {
  title = "Payout";

  filterKey = "payout";

  editable(): boolean {
    return false;
  }
}
export class PayoutExternalOffers extends Filter {
  title = "Payout external offers";

  filterKey = "payout_external_offers";

  editable(): boolean {
    return false;
  }
}
export class Revenue extends Filter {
  title = "Revenue";

  filterKey = "revenue";

  editable(): boolean {
    return false;
  }
}
export class RevenueInCurrency extends Filter {
  title = "Revenue In Currency";

  filterKey = "revenue_in_currency";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }
}
export class TNRevenue extends Filter {
  title = "TN Revenue";

  filterKey = "tn_revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class PartnerRevenue extends Filter {
  title = "Partner Revenue";

  filterKey = "partner_revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(4);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }
}

export class AltPartnerRevenue extends Filter {
  title = "Alt Partner Revenue";

  filterKey = "alt_partner_revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(4);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }
}

export class AltDefRevenue extends Filter {
  title = "Alt Def Revenue";

  filterKey = "alt_def_revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(4);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }
}

export class AltRevenue extends Filter {
  title = "Alt Revenue";

  filterKey = "alt_revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(4);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }
}

export class PartnerRevenueInCurrency extends Filter {
  title = "Partner Revenue In Currency";

  filterKey = "partner_revenue_in_currency";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(4);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }
}

export class Cards1D extends Filter {
  title = "Cards_1D";

  filterKey = "cards";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class OriginalLeads extends Filter {
  title = "Original Leads";

  filterKey = "original_leads";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}
export class Leads extends Filter {
  title = "Leads";

  filterKey = "leads";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}
export class BOClicks extends Filter {
  title = "BO Clicks";

  filterKey = "bo_clicks";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class SOClicks extends Filter {
  title = "SO Clicks";

  filterKey = "so_clicks";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class EpcTNRevenueClick extends Filter {
  title = "EPC (TN_Revenue/Click),$";

  filterKey = "epc_click2tn_revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class EPV extends Filter {
  title = "EPV,$";

  filterKey = "epv";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class CR extends Filter {
  title = "CR,%";

  filterKey = "cr";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class JumpCTR extends Filter {
  title = "Jump CTR,%";

  filterKey = "jump_ctr";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class BannerCTR extends Filter {
  title = "Banner CTR,%";

  filterKey = "banner_ctr";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Hit2Lead extends Filter {
  title = "Hit2Lead,%";

  filterKey = "hit2lead";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Hit2Conv extends Filter {
  title = "Hit2Conv,%";

  filterKey = "hit2conv";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Hit2Reg extends Filter {
  title = "Hit2Reg,%";

  filterKey = "hit2reg";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Visit2Lead extends Filter {
  title = "Visit2Lead,%";

  filterKey = "visit2lead";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Visit2Conv extends Filter {
  title = "Visit2Conv,%";

  filterKey = "visit2conv";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Visit2Reg extends Filter {
  title = "Visit2Reg,%";

  filterKey = "visit2reg";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Click2Lead extends Filter {
  title = "Click2Lead,%";

  filterKey = "click2lead";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Click2Reg extends Filter {
  title = "Click2Reg,%";

  filterKey = "click2reg";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class ROI extends Filter {
  title = "ROI,%";

  filterKey = "roi";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Profit extends Filter {
  title = "Profit,$";

  filterKey = "profit";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class CPL extends Filter {
  title = "CPL";

  filterKey = "cpl";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}
export class Rate extends Filter {
  title = "Rate";

  filterKey = "rate";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class PartnerCPReg extends Filter {
  title = "PartnerCPReg";

  filterKey = "partner_cp_reg";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class PartnerCPSale extends Filter {
  title = "PartnerCPSale";

  filterKey = "partner_cp_sale";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltEpcClick2Cost extends Filter {
  title = "alt EPC (Cost/alt Click),$";

  filterKey = "alt_epc_click2cost";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltEpcClick2Revenue extends Filter {
  title = "alt EPC (Alt Revenue/Alt Click,$";

  filterKey = "alt_epc_click2revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltEpcClick2RevenueWithCpm extends Filter {
  title = "alt EPC (Alt Revenue+CPM Revenue/Alt Click),$";

  filterKey = "alt_epc_click2revenue_with_cpm";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltEpv extends Filter {
  title = "alt EPV,$";

  filterKey = "alt_epv";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltCr extends Filter {
  title = "alt CR,%";

  filterKey = "alt_cr";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltJumpCtr extends Filter {
  title = "alt Jump CTR,%";

  filterKey = "alt_jump_ctr";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltCpl extends Filter {
  title = "alt CPL";

  filterKey = "alt_cpl";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltRate extends Filter {
  title = "alt Rate";

  filterKey = "alt_rate";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltRoi extends Filter {
  title = "alt ROI,%";

  filterKey = "alt_roi";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class AltProfit extends Filter {
  title = "alt Profit,$";

  filterKey = "alt_profit";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class Installs extends Filter {
  title = "Installs";

  filterKey = "installs";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class RegistrationsInApp extends Filter {
  title = "Registrations in app";

  filterKey = "registrations_in_app";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class EPCCostClick extends Filter {
  title = "EPC (Cost/Click),$";

  filterKey = "epc_click2cost";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class EPCRevenueClick extends Filter {
  title = "EPC (Revenue/Click),$";

  filterKey = "epc_click2revenue";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class EPCRevenueCPMRevenueClick extends Filter {
  title = "EPC (Revenue+CPM Revenue/Click),$";

  filterKey = "epc_click2revenue_with_cpm";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class NCR extends Filter {
  title = "NCR,%";

  filterKey = "ncr";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class RPT extends Filter {
  title = "RPT,$";

  filterKey = "rpt";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class EPreg extends Filter {
  title = "EPreg,$";

  filterKey = "ep_reg";

  editable(): boolean {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid;
  }
}

export class PassedGF extends Filter {
  title = "Passed GF";

  filterKey = "passed_general_filter";

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class PassesGFDryMode extends Filter {
  title = "Passed GF dry mode";

  filterKey = "passed_general_filter_dry_mode";

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  disabled(params: Params) {
    return params.groupBy === GROUP_BY_LIST.hour;
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: "1",
      no: "0",
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}

export class AdNetwork extends Filter {
  title = "Ad_Network";

  filterKey = "ad_network";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }
}

export class IsTarget extends Filter {
  title = "is_target";

  filterKey = "is_target";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: true,
      no: false,
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}

export class CPAClicks extends Filter {
  title = "CPA Clicks";

  filterKey = "cpa_clicks";

  editable(): boolean {
    return false;
  }
}

export class CPAConfirms extends Filter {
  title = "CPA Confirms";

  filterKey = "cpa_confirms";

  editable(): boolean {
    return false;
  }
}

export class CPAInstalls extends Filter {
  title = "CPA Installs";

  filterKey = "cpa_installs";

  editable(): boolean {
    return false;
  }
}

export class CPAPayments extends Filter {
  title = "CPA Payments";

  filterKey = "cpa_payments";

  editable(): boolean {
    return false;
  }
}

export class CPARegistrations extends Filter {
  title = "CPA Registrations";

  filterKey = "cpa_registrations";

  editable(): boolean {
    return false;
  }
}

export class CostForDecline extends Filter {
  title = "Cost to Auto-declines";

  filterKey = "cost_value";

  editable(): boolean {
    return false;
  }
}

export class MonetizationType extends Filter {
  title = "Monetization Type";

  filterKey = "monetization_type";

  validate(value: string) {
    return isLessThan(value, 255);
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class Stream extends Filter {
  title = "Stream";

  filterKey = "stream";

  validate(value: string) {
    return isLessThan(value, 255);
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class OfferCampaignID extends Filter {
  title = "Offer Campaign ID";

  filterKey = "cpa_offer_campaign_id";

  validate(value: string) {
    return isPositiveInteger(value);
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class GlobalCap extends Filter {
  title = "Global Cap";

  filterKey = "global_cap";

  validate(value: string) {
    return isPositiveInteger(value);
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class PartnerCommission extends Filter {
  title = "Partner Commission";

  filterKey = "partner_commission";

  editable() {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(4);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }
}

export class DefaultCommission extends Filter {
  title = "Default Commission";

  filterKey = "default_commission";

  editable() {
    return false;
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    if (typeof v === "number" && isFinite(v)) {
      return v.toFixed(4);
    }
    if (typeof v === "boolean") {
      return String(v);
    }
    return v;
  }
}

export class FunnelProvider extends Filter {
  title = "Funnel Provider";

  filterKey = "funnel_provider";

  validate(value: string) {
    return isLessThan(value, 120);
  }

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }
}

export class TdsBOCounter extends Filter {
  title = "Tds BO Counter";

  filterKey = "tds_bo_counter";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isPositiveInteger(value);
  }
}

export class TdsSOCounter extends Filter {
  title = "Tds SO Counter";

  filterKey = "tds_so_counter";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isPositiveInteger(value);
  }
}

export class TdsReasonCounter extends Filter {
  title = "Tds Reason Counter";

  filterKey = "entry_reason_counter";

  disabled(params: Params) {
    return params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isPositiveInteger(value);
  }
}

export class PartnerOfferName extends Filter {
  title = "Partner Offer Name";

  filterKey = "partner_offer_name";

  validate(value: string) {
    return isLessThan(value, 255);
  }

  disabled(params: Params) {
    return (
      params.groupBy === GROUP_BY_LIST.hour
    );
  }
}

export class LinkLocation extends Filter {
  title = "Link Location";

  filterKey = "link_location";

  // eslint-disable-next-line no-unused-vars
  prepareData(data: { [string]: any }, params: Params) {
    const v = data[this.filterKey];
    return (
      <ShowMoreLink link={v} />
    );
  }

  validate(value: string) {
    return isLessThan(value, 255);
  }

  disabled(params: Params) {
    return (
      params.groupBy === GROUP_BY_LIST.hour
    );
  }
}

export class IsBoosted extends Filter {
  title = "is_Boosted";

  filterKey = "is_boosted";

  disabled(params: Params) {
    return !params.tdsCid || params.groupBy === GROUP_BY_LIST.hour;
  }

  validate(value: string) {
    return isIn(value, ["yes", "no"]);
  }

  prepareDataType(data: { [p: string]: any }): Array<mixed> {
    const mapping = {
      yes: true,
      no: false,
    };
    return data[this.filterKey].map((value) => mapping[value]);
  }
}
