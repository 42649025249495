import { put, select, takeEvery } from "redux-saga/effects";
import _, { omitBy } from "lodash";
import { REPORT_TEMPLATE_TOGGLE_SWITCHER_SAGA } from "../reportTemplates.actions-types";
import {
  changeOrder,
  toggleTdsCid,
  toggleFilters,
  setPeriod,
  clearOldReportData,
  setHiddenFilters,
} from "../reportTemplates.actions";
import { selectCurrent, selectCurrentReportModel } from "../reportTemplates.selectors";

export function* toggleSwitcher(action) {
  const { checked, value } = action;
  const current = yield select((state) => selectCurrent(state.reportTemplates));
  const report = yield select((state) => selectCurrentReportModel(state.reportTemplates));
  const {
    fieldsOrder, params, filters, fieldsHidden,
  } = _.cloneDeep(current);
  params[value] = checked;

  const { tdsCid } = params;
  const dateKey = tdsCid ? "conversion_date" : "date";
  const prevDateKey = tdsCid ? "date" : "conversion_date";
  let newFilters = filters;

  if (value === "tdsCid") {
    yield put(toggleTdsCid(checked));
    if (checked) {
      newFilters.tds_cid = [];
      fieldsOrder.unshift("tds_cid");
    }

    newFilters = omitBy(
      { ...filters, [dateKey]: filters[prevDateKey] },
      (_value, key) => !(report.getFilterByKey(key).value(params) && Boolean(_value))
    );
  }

  const newFieldsOrder = fieldsOrder.filter((x) => x !== dateKey && newFilters[x]);

  const newHiddenFields = fieldsHidden.filter((x) => newFilters[x]);

  newFieldsOrder.unshift(dateKey);
  yield put(setPeriod(params.period));
  yield put(toggleFilters(newFilters));
  yield put(changeOrder(newFieldsOrder));
  yield put(setHiddenFilters(newHiddenFields));
  yield put(clearOldReportData());
}

export function* watchToggleSwitcherSaga() {
  yield takeEvery(REPORT_TEMPLATE_TOGGLE_SWITCHER_SAGA, toggleSwitcher);
}
