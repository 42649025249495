// @flow
import React from "react";
import {
  Button, ClickAwayListener, Box, Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import withStyles from "@mui/styles/withStyles";
import autobind from "react-autobind";
import SelectPopup from "./SelectPopup";
import PortalModal from "./PortalModal";

interface Props {
  items: string[];
  disabled: boolean;
  onDelete: (item: string) => void;
  checkValues: (value: string) => boolean;
  validationErrorMsg: string;
  onAddNew: (newOne: string) => void;
  scrollLeft: number;
  style: {};
  notCheckedItems: [],
  classes: any,
}

interface State {
  opened: boolean,
}

const styles = () => ({
  labelWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "& > span": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& > p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    textTransform: "none",
  },
  popupWrapper: {
    transform: "translateY(10px)",
    zIndex: 100,
    position: "relative",
  },
});

class HeaderSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      opened: false,
    };
    autobind(this, "toggle");
  }

  componentDidUpdate(prevProps: Props) {
    const { scrollLeft } = this.props;
    const { opened } = this.state;
    if (scrollLeft !== prevProps.scrollLeft && opened) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ opened: false });
    }
  }

  toggle() {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  }

  render() {
    const {
      items, onDelete, onAddNew, disabled, style, scrollLeft, notCheckedItems, classes, validationErrorMsg, checkValues,
    } = this.props;
    const { opened } = this.state;
    return (
      <div>
        {disabled && (
          <Button fullWidth disabled variant="outlined" data-testid="headerSelectButton">
            <Box className={classes.labelWrapper}>
              <Typography>
                {items.length > 1 ? `${items.length} values` : items[0]}
              </Typography>
              <KeyboardArrowDownIcon />
            </Box>
          </Button>
        )}
        {!disabled && (
          <Button fullWidth onClick={this.toggle} variant="outlined" data-testid="headerSelectButton">
            <Box className={classes.labelWrapper}>
              <Typography>
                {items.length > 1 ? `${items.length} values` : items[0]}
              </Typography>
              <KeyboardArrowDownIcon />
            </Box>
          </Button>
        )}
        {
          opened && (
            <PortalModal style={style} scrollLeft={scrollLeft}>
              <ClickAwayListener onClickAway={this.toggle}>
                <div className={classes.popupWrapper}>
                  <SelectPopup
                    checkValues={checkValues}
                    validationErrorMsg={validationErrorMsg}
                    onAddNew={onAddNew}
                    items={items}
                    notCheckedItems={notCheckedItems || []}
                    onDelete={onDelete}
                  />
                </div>
              </ClickAwayListener>
            </PortalModal>
          )
        }
      </div>
    );
  }
}

export default withStyles(styles)(HeaderSelect);
