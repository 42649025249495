// @flow
import React, { useRef, useState } from "react";
import {
  Button, ButtonGroup, Popper, Grow, Paper, MenuList, MenuItem, ClickAwayListener,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

const options: Array<{ label: string, value: string }> = [
  { label: "Correction all", value: "onCorrectionAll" },
  { label: "Correction payout", value: "onCorrectionPayout" },
  { label: "Correction revenue", value: "onCorrectionRevenue" },
];

export type Props = {
  onCorrectionAll: () => void,
  onCorrectionPayout: () => void,
  onCorrectionRevenue: () => void,
  disabled: boolean,
}
export default function SplitCostCorrectionBtn(props: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { disabled }: Props = props;

  const handleClick = () => {
    // eslint-disable-next-line react/destructuring-assignment
    props[options[selectedIndex].value]();
  };

  const handleMenuItemClick = (event: EventTarget, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleClose = (event: SyntheticEvent<HTMLDivElement>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <ButtonGroup
        color="secondary"
        variant="contained"
        ref={anchorRef}
        disabled={disabled}
      >
        <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
