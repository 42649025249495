// @flow
import type { StatelessFunctionalComponent } from "react";
/* eslint-disable import/max-dependencies */
import React, { createRef } from "react";
import _ from "lodash";
import autobind from "react-autobind";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { AutoSizer, MultiGrid, ScrollSync } from "react-virtualized";

import type {
  Row, Sorting,
} from "./types/ReportTable.types";
import { getSorting, stableSort, transformOrderBy } from "./utils";
import DirectionSelector from "../DirectionSelector";

import ApproveDialog from "../ApproveDialog";
import ReportTableFooter from "../ReportTableFooter";
import ReportTableHeaderCell from "../ReportTableHeaderCell";
import ReportTableCell from "../ReportTableCell";
import { PAGE_SIZE_OPTIONS } from "../ReportTableFooter/ReportTableFooter";
import type { Filters, Params } from "../../store/reportTemplates/types";
import type { ReportType } from "../../store/reportTemplates/model";
import type { GroupBy } from "../../store/reportTemplates/reportTemplates.constants";

type Props = {|
  rows: Row[],
  tableDataTotal: Filters[],
  filters: Filters,
  onFilterDelete: (filter: any) => void,
  onFilterAdd: (filter: any) => void,
  bakeReport: () => void;
  isBaking: boolean,
  reportType: ReportType,
  PrefixRowComponent?: ?StatelessFunctionalComponent<{row: Row, rowIndex: number }>,
  params: Params,
  selectGroupBy: (val: GroupBy) => void,
  groupBySelection: Array<{ label: string, value: GroupBy }>,
  isCheckedAll: boolean,
  onCheckAll: (boolean) => void,
  showCheckAll?: boolean,
  onChangeTableData: (data: Row[]) => void,
|}

interface State {
  scrollToRow: number,
  scrollToColumn: number,
  page: number,
  pageSize: number,
  sorting: Sorting,
  headingFilters: Filters,
}

const STYLE_FOR_VIRTUAL_PART = {
  height: "100%",
};

// styles for date coll
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: "1px solid #D4D9E5",
};
// style for date fixed cell
const STYLE_TOP_LEFT_GRID = {
  borderBottom: "1px solid #D4D9E5",
  borderRight: "1px solid #D4D9E5",
  borderRadius: "4px 0 0 0",
};
// styles for row with fixed data
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: "1px solid #D4D9E5",
};
// table data
const STYLE_BOTTOM_RIGHT_GRID = {
  borderBottom: "1px solid #D4D9E5",
  background: "#FFF",
};

// table header size
const HEADER_HEIGHT_SIZE = 120;
const SCROLL_HEIGHT_SIZE = 6;

class ReportTable extends React.Component<Props & {classes: Object}, State> {
  gridRef: { current: null | MultiGrid };

  headerRef: { current: null | MultiGrid };

  static defaultProps = {};

  constructor(props: Props & {classes: Object}) {
    super(props);
    // $FlowFixMe
    this.state = {
      scrollToRow: -1,
      scrollToColumn: -1,
      page: 0,
      pageSize: PAGE_SIZE_OPTIONS[0],
      sorting: {
        orderBy: "date",
        order: "asc",
      },
      headingFilters: {},
    };

    this.gridRef = createRef();
    this.headerRef = createRef();
    autobind(this, "setPage", "setSorting", "changePageSize", "createSortingHandler", "cellRenderer", "scrollTo", "onFilterAdd", "cellHeaderRenderer");
  }

  componentDidMount() {
    const { filters, params } = this.props;
    const orderBy = params.tdsCid ? "conversion_date" : "date";
    // todo: maybe filters must be merge to headingFilters always when filters changed
    this.setState({ headingFilters: filters });
    this.setSorting({
      orderBy,
      order: "asc",
    });
  }

  componentDidUpdate() {
    // TODO: need update this
    // refresh teable data after sorting
    if (this.gridRef.current) {
      this.gridRef.current.forceUpdateGrids();
    }
    if (this.headerRef.current) {
      this.headerRef.current.forceUpdateGrids();
    }
  }

  setScrollToRow(val: number) {
    this.setState({ scrollToRow: val });
  }

  setScrollToColumn(val: number) {
    this.setState({ scrollToColumn: val });
  }

  setPageSize(val: number) {
    this.setState({ pageSize: val });
  }

  setPage(val: number) {
    this.setState({ page: val, scrollToRow: 0 });
  }

  setSorting(val: Sorting) {
    const {
      rows, params, reportType, onCheckAll, onChangeTableData,
    } = this.props;
    onCheckAll(false);
    const prepearedOrderBy = transformOrderBy(val.orderBy, params, reportType);
    const sort = stableSort(rows, getSorting(val.order, prepearedOrderBy));
    this.setState({ sorting: val, page: 0 });
    onChangeTableData(sort);
  }

  changePageSize(val: number) {
    this.setPageSize(val);
    this.setPage(0);
  }

  createSortingHandler(orderBy: string) {
    const { sorting } = this.state;
    if (sorting.orderBy === orderBy) {
      this.setSorting({ orderBy, order: sorting.order === "asc" ? "desc" : "asc" });
    }
    else {
      this.setSorting({ orderBy, order: "asc" });
    }
  }

  cellRenderer({
    columnIndex, key, rowIndex, style,
  }: {columnIndex: number, key: string, rowIndex: number, style: Object}) {
    const {
      pageSize, page,
    } = this.state;
    const {
      filters, rows, PrefixRowComponent, params, reportType,
    } = this.props;
    const row = rows[rowIndex + (pageSize * page)];
    if (!row) {
      return null;
    }

    return (
      <ReportTableCell
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        PrefixRowComponent={columnIndex === 0 ? PrefixRowComponent : null}
        row={row}
        filters={filters}
        key={key}
        style={style}
        params={params}
        reportType={reportType}
      />
    );
  }

  scrollTo(direction: string) {
    this.scrollToHandlers()[direction]();
  }

  onFilterAdd({ value, key }: {value: string[], key: string}) {
    const { onFilterAdd } = this.props;
    onFilterAdd({ value, key });
    const { headingFilters } = this.state;

    value.forEach((v) => {
      if (!headingFilters[key].includes(v)) {
        headingFilters[key].push(v);
      }
    });

    this.setState({ headingFilters });
  }

  cellHeaderRenderer({
    columnIndex, key, style, parent,
  }: {columnIndex: number, key: string, style: Object, parent: {state: {scrollLeft: number}}}) {
    const { sorting, headingFilters } = this.state;
    const {
      // eslint-disable-next-line no-shadow
      filters, onFilterDelete, tableDataTotal, params, selectGroupBy, groupBySelection, reportType,
    } = this.props;
    const { scrollLeft } = parent.state;

    return (
      <ReportTableHeaderCell
        reportType={reportType}
        groupBySelection={groupBySelection}
        columnIndex={columnIndex}
        filters={filters}
        tableDataTotal={tableDataTotal}
        headingFilters={headingFilters}
        key={key}
        style={style}
        onFilterDelete={onFilterDelete}
        onFilterAdd={this.onFilterAdd}
        createSortingHandler={this.createSortingHandler}
        sorting={sorting}
        scrollLeft={scrollLeft}
        params={params}
        selectGroupBy={selectGroupBy}
      />
    );
  }

  _onScroll(onScroll: (any) => any, params: any) {
    this.setScrollToColumn(-1);
    this.setScrollToRow(-1);
    return onScroll(params);
  }

  scrollToHandlers() {
    const { pageSize } = this.state;
    const { filters, rows } = this.props;
    const heading = Object.keys(filters);
    return {
      top: () => this.setScrollToRow(0),
      bottom: () => this.setScrollToRow(Math.min(pageSize, rows.length) - 1),
      left: () => this.setScrollToColumn(1),
      right: () => this.setScrollToColumn(heading.length - 1),
    };
  }

  render() {
    const {
      classes,
      filters,
      rows,
      isBaking,
      bakeReport,
      isCheckedAll,
      onCheckAll,
      showCheckAll,
    } = this.props;
    const {
      scrollToRow, scrollToColumn, pageSize, page,
    } = this.state;
    const heading = Object.keys(filters);
    const rowsCount = rows.length - (pageSize * page);
    const isDataEmpty = !rows || !rows.length;

    return (
      <>
        <Box display="flex">
          <DirectionSelector onDirectionChange={this.scrollTo} />
          {showCheckAll && (
            <FormControlLabel
              label="Check all"
              control={(
                <Checkbox
                  checked={isCheckedAll}
                  onChange={(e) => onCheckAll(e.target.checked)}
                />
              )}
            />
          )}
        </Box>
        <Box component="div" className={classes.root}>

          <Box component="div" className={classes.tableBody}>
            <div style={STYLE_FOR_VIRTUAL_PART}>
              <ScrollSync>
                {({ onScroll, scrollLeft }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <div id="reportTableBody" style={STYLE_FOR_VIRTUAL_PART}>
                        <MultiGrid
                          ref={this.headerRef}
                          cellRenderer={this.cellHeaderRenderer}
                          columnCount={heading.length}
                          columnWidth={225}
                          height={HEADER_HEIGHT_SIZE + SCROLL_HEIGHT_SIZE}
                          rowCount={1}
                          rowHeight={HEADER_HEIGHT_SIZE}
                          width={width}
                          scrollLeft={scrollLeft}
                          fixedRowCount={isBaking || isDataEmpty ? 0 : 1}
                          fixedColumnCount={1}
                          styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
                          styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                          onScroll={(params) => this._onScroll(onScroll, params)}
                        />
                        {(!isDataEmpty && !isBaking) && (
                          <MultiGrid
                            ref={this.gridRef}
                            scrollToRow={scrollToRow}
                            scrollToColumn={scrollToColumn}
                            cellRenderer={this.cellRenderer}
                            columnWidth={225}
                            columnCount={heading.length}
                            fixedColumnCount={1}
                            height={height - (HEADER_HEIGHT_SIZE + SCROLL_HEIGHT_SIZE)}
                            rowHeight={50}
                            width={width}
                            rowCount={rowsCount < pageSize ? rowsCount : pageSize}
                            styleBottomRightGrid={STYLE_BOTTOM_RIGHT_GRID}
                            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                            onScroll={(params) => this._onScroll(onScroll, params)}
                          />
                        )}
                      </div>
                    )}
                  </AutoSizer>
                )}
              </ScrollSync>
            </div>
          </Box>
          {isDataEmpty && !isBaking && (
            <ApproveDialog
              text="No entries for selected dates or filters."
            />
          )}
          {isBaking && (
            <ApproveDialog
              text="Your report is too large. The report is available for baking."
              action={bakeReport}
            />
          )}
          {!isDataEmpty && !isBaking && (
            <ReportTableFooter
              count={rows.length}
              page={page}
              pageSize={pageSize}
              onChangePage={this.setPage}
              onChangeRowsPerPage={this.changePageSize}
            />
          )}
        </Box>
      </>
    );
  }
}
ReportTable.defaultProps = {
  PrefixRowComponent: undefined,
};

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(1)}px`,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    background: `${theme.palette.background.darkDefault}`,
    borderRadius: `${theme.shape.borderRadius}px`,
    height: (props) => (!props.rows || !props.rows.length || props.isBaking ? "auto" : "100%"),
  },
  tableBody: {
    height: (props) => (!props.rows || !props.rows.length || props.isBaking ? `${HEADER_HEIGHT_SIZE + SCROLL_HEIGHT_SIZE}px` : "100%"),
  },
});

const isEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);

export default React.memo<Props>(withStyles(styles)(ReportTable), isEqual);
