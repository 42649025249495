// @flow
/* eslint-disable flowtype/no-types-missing-file-annotation */
import dayjs from "dayjs";
import type { Template } from "./types";
import {
  GROUP_BY_LIST,
  PERIOD,
} from "./reportTemplates.constants";
import type { ReportType } from "./model";

export const getNewTemplate = (reportType: ReportType): Template => {
  const filters = {};
  const now = dayjs(Date.now());
  filters.date = [
    {
      to: now.format("YYYY-MM-DD"),
      from: now.format("YYYY-MM-DD"),
    },
  ];

  const fieldsOrder = Object.keys(filters).filter((item) => filters[item]);

  const defaultParams = {
    groupBy: GROUP_BY_LIST.day,
    currentDay: true,
    tdsCid: false,
    period: PERIOD.today,
  };

  return ({
    id: "", // new template without id, we getting id from backend after save
    createdBy: "",
    reportType,
    filters,
    name: "New template",
    author: "You",
    fieldsOrder,
    fieldsHidden: [],
    params: defaultParams,
  });
};

export const getActiveFieldsOrder :(any)=>Array<string> = ({
  fieldsOrder,
  newActiveList,
}: { fieldsOrder: Array<string>, newActiveList: Array<string> }) => {
  if (!fieldsOrder || !newActiveList) {
    return [];
  }

  return [...new Set([...fieldsOrder, ...newActiveList])];
};

export const getOrderedSelectedFilters = ({
  orderList,
  filersList,
}: { orderList: Array<string>, filersList : {} }) => {
  if (!orderList || !filersList) {
    return null;
  }
  const result = {};
  orderList.forEach((item) => {
    result[item] = filersList[item];
  });

  return result;
};
