// @flow

function getBreadcrumbs(path: string): Array<*> {
  const firstStep = path.split("/")[1];
  const pathTitleMap = {
    "managersReport": "For management",
    "accountantsReport": "For accounting",
  };
  return [{ path, title: pathTitleMap[firstStep] || firstStep }];
}

export default getBreadcrumbs;
