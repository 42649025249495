import { call, put, takeEvery } from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { updateTemplatesList } from "../reportTemplates.actions";
import { REPORT_TEMPLATE_DELETE_SAGA } from "../reportTemplates.actions-types";
import { MESSAGES } from "../reportTemplates.constants";

export function* deleteTemplate(action) {
  try {
    yield put({ type: "START_DELETE_TEMPLATE" });

    yield call(api.deleteTemplate, action.payload);

    yield put({ type: "SUCCESS_DELETE_TEMPLATE" });
    yield put(addNotification({ message: MESSAGES.SUCCESS_DELETE_TEMPLATE, severity: "success" }));

    yield put(updateTemplatesList());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || MESSAGES.ERROR_DELETE_TEMPLATE, severity: "error" }));
    yield put({ type: "ERROR_DELETE_TEMPLATE", error });
  }
}

export function* watchDeleteTemplate() {
  yield takeEvery(REPORT_TEMPLATE_DELETE_SAGA, deleteTemplate);
}
