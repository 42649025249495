// @flow
import Report from "./Report";
import manager from "./manager.config";
import accountant from "./accountant.config";

const reportsMapConfig = {
  manager,
  accountant,
};

const reportsMapModel = {};

export type ReportType = $Keys<typeof reportsMapConfig>;

// eslint-disable-next-line import/prefer-default-export
export const getReportModel = (reportType: ReportType): Report => {
  if (reportsMapModel[reportType]) {
    return reportsMapModel[reportType];
  }
  const config = reportsMapConfig[reportType];
  const report = new Report(config);
  reportsMapModel[reportType] = report;
  return report;
};
