// @flow
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { MESSAGES } from "../../reportTemplates/reportTemplates.constants";
import type { OpenModalSagaAction } from "../actions";
import { COST_CORRECTION_OPEN_MODAL_SAGA } from "../actions-types";
import {
  endLoading,
  openModal, setModalValues, setTransactionData, startLoading,
} from "../actions";
import type { Dictionary, TransactionData } from "../types";
import type { CommonState } from "../../preloadState";

export function* openCostCorrection(action: OpenModalSagaAction): Saga<void> {
  const { tds_cid: tdsCid, date, currentDay } = action;
  try {
    const needToLoadDictionary: boolean = yield select((state: CommonState) => state.costCorrection.dictionary
      .declineReason.length === 0);

    yield put(startLoading());
    if (tdsCid && date) {
      const transactionData: TransactionData = yield call(api.getTransactionData, {
        tds_cid: tdsCid,
        date,
        currentDay,
      });
      if (!transactionData) {
        yield put(addNotification({ message: MESSAGES.ERROR_GET_TRANSACTION_DATA, severity: "error" }));
        return;
      }
      yield put(setTransactionData(transactionData));
    }

    if (needToLoadDictionary) {
      const dictionary: Dictionary = yield call(api.getCostCorrectionDictionary);
      yield put(setModalValues(dictionary));
    }

    yield put(openModal());

    yield put(addNotification({
      message: MESSAGES.SUCCESS_GET_TRANSACTION_DATA,
      severity: "success",
    }));
  }
  catch (error) {
    yield put(addNotification({
      message: error.errorMessage || MESSAGES.ERROR_GET_TRANSACTION_DATA,
      severity: "error",
    }));
  }
  finally {
    yield put(endLoading());
  }
}

export function* watchOpenCostCorrection(): Saga<void> {
  yield takeEvery(COST_CORRECTION_OPEN_MODAL_SAGA, openCostCorrection);
}
