/* eslint-disable import/max-dependencies */
// @flow
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Box,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DateRangeSelector from "../DateRangeSelectorContainer";
import SwitchWithInfo from "./SwitchWithInfo";
import GroupBySelect from "../GroupBySelect";
// TODO: rewrite on generic
import type { Params } from "../../store/reportTemplates/types/Template";
import "./style.css";
import type { Hints, Toggle } from "../../store/reportTemplates/model/types/index.types";
import type { GroupBy } from "../../store/reportTemplates/reportTemplates.constants";

type Props = {|
  params: Params,
  onClick: ({ value: string, checked: boolean }) => void;
  onClose: () => void;
  groupBy: string;
  toggles: Toggle[];
  setGroupBy: (GroupBy) => void;
  groupBySelection: Array<{ label: string, value: GroupBy }>,
  hints: Hints,
|}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.appBar,
  },
  headerSelectors: {
    minWidth: 450,
    justifyContent: "space-between",
    marginRight: theme.spacing(4),
  },
}));

const ModalHeader = (props: Props) => {
  const {
    params,
    onClick,
    onClose,
    groupBy,
    setGroupBy,
    toggles,
    groupBySelection,
    hints,
  } = props;
  const classes = useStyles();

  return (
    <AppBar
      position="relative"
      color="primary"
      className={classes.appBar}
    >
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              className={classes.headerSelectors}
              justifyContent="space-between"
            >
              <GroupBySelect
                selection={groupBySelection}
                value={groupBy}
                onChange={(e) => setGroupBy(e.target.value)}
              />
              {hints.groupBy && (
                <Tooltip title={hints.groupBy}>
                  <HelpOutlineIcon />
                </Tooltip>
              )}
              <DateRangeSelector />
              {hints.datePicker && (
                <Tooltip title={hints.datePicker}>
                  <HelpOutlineIcon />
                </Tooltip>
              )}
            </Box>
            {toggles.map(({ label, hint, value }: Toggle) => (
              <Box
                mr={4}
                key={label}
                data-testid={label
                  .toLowerCase()
                  .split(" ")
                  .join("_")}
              >
                <SwitchWithInfo
                  label={label}
                  hint={hint}
                  checked={params[value]}
                  onClick={() => onClick({ value, checked: !params[value] })}
                />
              </Box>
            ))}
          </Box>
          <IconButton onClick={onClose} edge="end" color="inherit" size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo<Props>(ModalHeader);
