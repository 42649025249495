// @flow
/* eslint-disable import/max-dependencies */
import React, { useState } from "react";
import { get } from "lodash";
import { MenuItem, TableSortLabel, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HeaderSelect from "../HeaderSelect/HeaderSelect";
import type { Sorting } from "../ReportTable/types/ReportTable.types";
import type { Filters, Params } from "../../store/reportTemplates/types";
import GroupBySelect from "../GroupBySelect";
import "./style.css";
import DateRangeSelector from "../DateRangeSelectorContainer";
import { getReportModel } from "../../store/reportTemplates/model";
import type { ReportType } from "../../store/reportTemplates/model";
import type { GroupBy } from "../../store/reportTemplates/reportTemplates.constants";

type Props = {|
  columnIndex: number,
  filters: Filters;
  tableDataTotal: Filters[],
  headingFilters: Filters;
  scrollLeft: number,
  onFilterDelete: ({ value: string, key: string }) => void,
  onFilterAdd: ({ value: string[], key: string }) => void,
  createSortingHandler: (orderBy: string) => void,
  sorting: Sorting,
  style: any,
  params: Params,
  reportType: ReportType,
  selectGroupBy: (val: GroupBy) => void,
  groupBySelection: Array<{ label: string, value: GroupBy }>,
|}

const useStyles = makeStyles((theme) => ({
  tableHeaderCell: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    boxSizing: "border-box",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  sortRoot: {
    marginBottom: "5px",
    fontWeight: 500,
    textTransform: "uppercase",
    flexDirection: "row",
    "&:hover": {
      color: `${theme.palette.text.primary}`,
    },
  },
  totalLabel: {
    fontWeight: 500,
    marginTop: "5px",
    minHeight: "17px",
  },
  dateRangeSelectorMenuItem: {
    overflow: "visible",
  },
}));

const ReportTableHeaderCell = ({
  columnIndex,
  tableDataTotal,
  filters,
  headingFilters,
  sorting,
  scrollLeft,
  style,
  onFilterDelete,
  onFilterAdd,
  createSortingHandler,
  params,
  selectGroupBy,
  groupBySelection,
  reportType,
}: Props) => {
  const classes = useStyles();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSelectGroupBy = (val) => {
    if (!val) {
      setIsSelectOpen(true);
    }
    selectGroupBy(val);
  };
  const handleClose = () => {
    setIsSelectOpen(false);
  };
  const heading = Object.keys(filters);
  const orderBy = heading[columnIndex];
  const filterData = filters[orderBy];
  const notCheckedFilterData = headingFilters[orderBy];
  let isDateCol = false;

  if (orderBy === "date" || orderBy === "conversion_date") {
    isDateCol = true;
  }
  const filterModel = getReportModel(reportType).getFilterByKey(orderBy);
  const totalCount = get(tableDataTotal, `[0][${orderBy}]`, "") && filterModel.prepareData(tableDataTotal[0], params);

  return (
    <div
      style={style}
      className={classes.tableHeaderCell}
      data-testid={`${orderBy}HeaderSelect`}
    >
      <TableSortLabel
        className={classes.sortRoot}
        component="div"
        direction={sorting.order}
        active={sorting.orderBy === orderBy}
        onClick={() => createSortingHandler(orderBy)}
        IconComponent={ExpandMoreIcon}
      >
        {filterModel.getTitle() || orderBy}
      </TableSortLabel>
      {isDateCol && (
        <>
          <GroupBySelect
            selection={groupBySelection}
            value={params.groupBy}
            onChange={(e) => handleSelectGroupBy(e.target.value)}
            open={isSelectOpen}
            onOpen={() => setIsSelectOpen(true)}
            onClose={handleClose}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <MenuItem
                className={classes.dateRangeSelectorMenuItem}
                key="daterange-key"
                value={undefined}
              >
                <DateRangeSelector />
              </MenuItem>
            </div>
          </GroupBySelect>
        </>
      )}
      {!isDateCol && (
        <HeaderSelect
          disabled={!filterModel.editable()}
          items={filterData}
          notCheckedItems={notCheckedFilterData}
          onDelete={(filter) => onFilterDelete({ value: filter, key: orderBy })}
          onAddNew={(filter) => {
            onFilterAdd({ key: orderBy, value: filterModel.getValue(filter) });
          }}
          style={style}
          scrollLeft={scrollLeft}
          checkValues={filterModel.validate}
          validationErrorMsg={filterModel.getErrorMsg()}
        />
      )}
      <Typography className={classes.totalLabel}>
        {totalCount}
      </Typography>
    </div>
  );
};

export default ReportTableHeaderCell;
