// @flow
import React from "react";
import type { Node } from "react";
import withStyles from "@mui/styles/withStyles";
import ReactDOM from "react-dom";

interface Props {
  style: {left: number},
  children: Iterable<?Node>,
  scrollLeft: number
}

class PortalModal extends React.Component<Props> {
  el: HTMLElement;

  target: HTMLElement;

  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.el.className = "portalModal";
    // todo maybe get target from props
    this.target = document.getElementById("reportTableBody") || document.body || document.createElement("div");
  }

  componentDidMount() {
    const { style, scrollLeft } = this.props;
    this.el.style.left = `${style.left + 225 - scrollLeft}px`;
    this.el.style.top = `${100}px`;

    this.target.appendChild(this.el);
  }

  componentWillUnmount() {
    this.el.parentNode && this.el.parentNode.removeChild(this.el);
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(
      children,
      this.el
    );
  }
}

const style = {
  "@global": {
    ".portalModal": {
      position: "absolute",
    },
  },
};

export default withStyles(style)(PortalModal);
