// @flow
import React, { useState } from "react";
import {
  Paper, Checkbox, FormControlLabel, TextField, Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { onKeyWrapper } from "../../core/on-key";

interface Props {
  items: string[];
  onDelete: (item: string) => void;
  onAddNew: (newOne: string) => void;
  notCheckedItems: [];
  checkValues: (value: string) => boolean;
  validationErrorMsg: string;
}

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    maxHeight: "250px",
    maxWidth: "500px",
    overflowY: "auto",
    background: `${theme.palette.background.darkDefault}`,
  },
  wrapValues: {
    borderTop: `1px solid ${theme.palette.borderColor}`,
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)} 0 0`,
  },
  labelRow: {
    display: "flex",
    "& > .MuiTypography-root": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
}));

const SelectPopup = ({
  items, onDelete, onAddNew, notCheckedItems, checkValues, validationErrorMsg,
}: Props) => {
  const [newValue, setNewValue] = useState<string>("");
  const isValid = checkValues(newValue);
  const classes = useStyle();
  const onValueChange = ({ target: { value } }) => setNewValue(value);

  const onCheckBoxChange = ({ target, item }) => {
    if (target.checked) {
      onAddNew(item);
    }
    else {
      onDelete(item);
    }
  };

  const onEnter = onKeyWrapper("Enter")(() => {
    if (newValue && isValid) {
      onAddNew(newValue);
      setNewValue("");
    }
  });

  return (
    <Paper elevation={4} classes={{ root: classes.root }}>
      <TextField
        variant="outlined"
        placeholder="Start type to select values"
        value={newValue}
        margin="dense"
        color="primary"
        onChange={onValueChange}
        onKeyPress={onEnter}
        data-testid="headerSelectInput"
        helperText={Boolean(!isValid && newValue) && validationErrorMsg}
        error={Boolean(!isValid && newValue)}
      />
      {notCheckedItems && !!notCheckedItems.length && (
        <div className={classes.wrapValues}>
          <Typography varian="h6">
            Selected values
          </Typography>
          {
            notCheckedItems.map((item: string) => (
              <FormControlLabel
                className={classes.labelRow}
                key={item}
                control={(
                  <Checkbox
                    color="secondary"
                    onClick={(e) => onCheckBoxChange({ target: e.target, item })}
                    checked={items.includes(item)}
                  />
                )}
                label={item}
              />
            ))
          }
        </div>
      )}

    </Paper>
  );
};

export default SelectPopup;
