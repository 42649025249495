// @flow
import React from "react";
import { Provider } from "react-redux";
import type { ReportType } from "../../store/reportTemplates/model";
import getStore from "../../store";
import ReportContainer from "../../components/ReportContainer";
import MessageInfo from "../../components/MessageInfo";
import PrivateComponent from "../../components/PrivateComponent";

export default ({ reportTypePage, aclRule }: { reportTypePage: ReportType, aclRule: string }) => (
  <PrivateComponent aclRule={aclRule}>
    <Provider store={getStore(reportTypePage)}>
      <ReportContainer />
      <MessageInfo />
    </Provider>
  </PrivateComponent>
);
