// @flow
function isSafeNumber(str: string) {
  return Math.abs(Number(str)) <= Number.MAX_SAFE_INTEGER;
}

export function isInteger(str: string) {
  return new RegExp("^-[0-9]|^[0-9]+$").test(str) && isSafeNumber(str);
}

export function isFloatNumber(str: string) {
  return new RegExp("^[0-9]*[.,]?[0-9]+$").test(str) && isSafeNumber(str);
}

export function isPositiveInteger(str: string) {
  return isInteger(str) && Number(str) > 0;
}

export function isLatinOnly(str: string) {
  return new RegExp("^[a-zA-Z]+$").test(str);
}

export function isLatinWithNumbers(str: string) {
  return new RegExp("^[a-zA-Z0-9]+$").test(str);
}

export function isLatinWithWhitespace(str: string) {
  return new RegExp("(^(?!\\s+$))(^[a-zA-Z\\s]+$)").test(str);
}

export function isLessThan(str: string, length: number) {
  return new RegExp("(^(?!\\s+$))(^.*$)").test(str) && str.length <= length;
}

export function isLatinWithSpecSymbols(str: string) {
  return new RegExp("^[a-zA-Z.//-]+$").test(str);
}

/**
 * Verify everything that isLatinWithSpecSymbols does, plus plus ",", "{" and "}" characters and whitespace
 * @param {string} str value
 * @returns {boolean} is value valid
 */
export function isLatinWithSpecAddtlSymbolsWhitespace(str: string) {
  return new RegExp("(^(?!\\s+$))(^[a-zA-Z.,{}//-\\s]+$)").test(str);
}

export function isLatinNumbersSpecSymbols(str: string) {
  return new RegExp("^[a-zA-Z0-9.//-]+$").test(str);
}

/**
 * Verify everything that isLatinNumbersSpecSymbols does, plus "_" character
 * @param {string} str value
 * @returns {boolean} is value valid
 */
export function isLatinNumbersAddtlSpecSymbols(str: string) {
  return new RegExp("^[a-zA-Z0-9._//-]+$").test(str);
}

export function isLatinNumbersSpecSymbolsWhitespace(str: string) {
  return new RegExp("(^(?!\\s+$))(^[a-zA-Z0-9.//-\\s]+$)").test(str);
}

/**
 * Verify everything that isLatinNumbersSpecSymbolsWhitespace does, plus ",", "{" and "}" characters
 * @param {string} str value
 * @returns {boolean} is value valid
 */
export function isLatinNumbersAddtlSpecSymbolsWhitespace(str: string) {
  return new RegExp("(^(?!\\s+$))(^[a-zA-Z0-9.,{}//-\\s]+$)").test(str);
}

export function isNumberWithSpecSymbols(str: string) {
  return new RegExp("^[0-9.//-]+$").test(str);
}

export function isBoolean(str: string) {
  return ["true", "false"].includes(str);
}

export function isIn<T>(value: T, values: T[]): boolean {
  return values.includes(value);
}
