// @flow
/* eslint-disable import/max-dependencies */
import environment from "environment";
import RequestService from "@fas/ui-framework/lib/services/request";
import { userService } from "./user";
import type { Template, Params, Filter } from "../store/reportTemplates/types";
import type { BakendTemplate } from "../store/reportTemplates/types/BakendTemplate";
import type { Item } from "../components/TemplateSelect/types/List";
import type {
  Dictionary,
  TransactionData,
} from "../store/costCorrection/types";
import { getReportModel } from "../store/reportTemplates/model";
import type { ReportType } from "../store/reportTemplates/model";

const getAuthorizationHeaders: () => { [key: string]: mixed } = () => ({
  "X-Api-Key": userService.getApiKey() || "",
});

export const requestService: RequestService = new RequestService({
  withCredentials: true,
  customAttr: { getAuthorizationHeaders },
});

requestService.getInstance().interceptors.response.use(
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error && error.response && error.response.status === 401) {
      window.location = `${environment.baseUrl}?r=${window.location.href}`;
    }
    return Promise.reject(error);
  }
);

function prepareTemplateToServer({
  id,
  name,
  filters,
  fieldsOrder,
  fieldsHidden,
  params,
  reportType = "manager",
}: Template) {
  const {
    currentDay, tdsCid, period, groupBy,
  } = params;

  const report = getReportModel(reportType);
  const orderedFilters = {};
  const headers = [];

  fieldsOrder.forEach((fieldKey) => {
    const filterModel = report.getFilterByKey(fieldKey);

    orderedFilters[fieldKey] = filterModel.prepareDataType(filters, params);

    headers.push(...filterModel.getHeadersObject(params));
  });

  return {
    id,
    name: (name || "").trim(),
    includeCurrentDay: currentDay,
    needTdsCid: tdsCid,
    filters: orderedFilters,
    fieldsHidden,
    period,
    groupBy,
    headers,
    reportType,
  };
}

function prepareTemplateToMongo({
  id, name, filters, fieldsOrder, fieldsHidden, reportType, params: {
    currentDay, tdsCid, period, groupBy,
  },
}: Template) {
  const preparedData = { ...filters };
  if (preparedData.date) {
    preparedData.date = [];
  }
  if (preparedData.conversion_date) {
    preparedData.conversion_date = [];
  }

  const orderedFilters = {};

  fieldsOrder.forEach((fieldKey) => {
    orderedFilters[fieldKey] = preparedData[fieldKey];
  });

  return {
    id,
    name: (name || "").trim(),
    includeCurrentDay: currentDay,
    needTdsCid: tdsCid,
    filters: orderedFilters,
    fieldsHidden,
    period,
    groupBy,
    reportType,
  };
}

const prepareTemplateToFront = (template: BakendTemplate): Template => {
  const {
    id,
    name,
    department,
    author,
    createdBy,
    reportType = "manager",
    settings: {
      includeCurrentDay, needTdsCid, period, groupBy,
    },
    filters,
    fieldsHidden = [],
  } = template;

  const fieldsOrder = Object.keys(filters);

  const params: Params = {
    currentDay: includeCurrentDay,
    tdsCid: needTdsCid,
    period,
    groupBy,
  };

  return {
    id,
    name,
    params,
    filters,
    author,
    department,
    createdBy,
    fieldsOrder,
    fieldsHidden,
    reportType,
  };
};

export default {
  getTemplatesList: ({
    searchStr,
    reportType,
  }: {
    searchStr: string,
    reportType: ReportType
  }): Promise<Array<Item>> => {
    const config: any = {
      params: { reportType },
    };

    if (searchStr) {
      config.params = Object.assign({}, config.params, { searchStr });
    }

    return requestService
      .get(environment.endpoints.get.templateList, config)
      .then((r) => r.data.list.map((i) => prepareTemplateToFront(i)));
  },

  createTemplate: (opt: Template): Promise<Template> => requestService
    .put(
      environment.endpoints.put.createTemplate,
      prepareTemplateToMongo(opt)
    )
    .then((e) => prepareTemplateToFront(e.data.template)),

  getTemplate: (opt: { id: string }): Promise<Template> => requestService
    .get(environment.endpoints.get.getTemplate, {
      params: {
        templateId: opt.id,
      },
    })
    .then((e) => prepareTemplateToFront(e.data.template)),

  updateTemplate: (opt: Template): Promise<Template> => requestService
    .post(
      environment.endpoints.post.updateTemplate,
      prepareTemplateToMongo(opt),
      {
        params: {
          templateId: opt.id,
        },
      }
    )
    .then((e) => prepareTemplateToFront(e.data.template)),

  deleteTemplate: (opt: { id: string }): Promise<any> => requestService.delete(
    environment.endpoints.delete.deleteTemplate,
    {
      params: { templateId: opt.id },
    }
  ),

  getReport: (opt: Template): Promise<any> => requestService.post(
    environment.endpoints.post.getReport,
    prepareTemplateToServer(opt),
    { timeout: environment.timeout }
  ),

  bakeReport: (opt: Template): Promise<any> => requestService.post(environment.endpoints.post.bakeReport, {
    ...prepareTemplateToServer(opt),
    bakeryOutputType: opt.reportType === "accountant" ? "xlsx" : "csv",
  }),

  getTransactionData: (opt: {
    tds_cid: string,
    date: string,
    currentDay?: boolean
  }): Promise<TransactionData> => requestService
    .get(environment.endpoints.get.costCorrectionTransactionData.replace("{tds_cid}", opt.tds_cid), {
      params: {
        date: opt.date,
        includeCurrentDay: opt.currentDay,
      },
    })
    .then((e) => e.data.data),

  updateTransactionData: (opt: {
    payout?: number,
    revenue?: number,
    isDeclined: boolean,
    declineReason: Array<string>,
    dimensions: Array<{
      tds_cid?: Filter<string>,
      conversion_date?: Filter<string>
    }>
  }): Promise<any> => requestService.put(
    environment.endpoints.put.costCorrectionUpdateTransactionData,
    opt
  ),

  getTransactionStatus: (opt: { tds_cid: string }): Promise<boolean> => requestService
    .get(environment.endpoints.get.costCorrectionTransactionStatus, {
      params: {
        tds_cid: opt.tds_cid,
      },
    })
    .then((e) => e.data.status),

  getCostCorrectionDictionary: (): Promise<Dictionary> => requestService
    .get(environment.endpoints.get.costCorrectionDictionary)
    .then((e) => e.data.data),
};
