// @flow
import React from "react";
import { Box, Button, Grid } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Download from "@mui/icons-material/Download";
import type { State as ReportTemplates, Template } from "../../store/reportTemplates/types";
import TemplateSelect from "../TemplateSelect/TemplateSeclect";
import { disableSave } from "../../store/reportTemplates/reportTemplates.selectors";
import SplitCostCorrectionBtn from "./SplitCostCorrectionBtn";

export type Props = {
  reportTemplates: ReportTemplates,
  onAddNew: () => void,
  onSelect: (item: Template) => void,
  onSearchTemplate: (value: string) => void,
  classes: *,
  isDisabledEdit: boolean,
  onEditClick: () => void,
  onDownloadClick: () => void,
  onApplyClick: () => void,
  onCorrectionAll: () => void,
  onCorrectionPayout: () => void,
  onCorrectionRevenue: () => void,
  disabledCorrection: boolean,
}

export default function ActionRow(props: Props) {
  const {
    reportTemplates,
    onAddNew,
    onSelect,
    onSearchTemplate,
    classes,
    isDisabledEdit,
    onEditClick,
    onDownloadClick,
    onApplyClick,
    onCorrectionAll,
    onCorrectionPayout,
    onCorrectionRevenue,
    disabledCorrection,
  } = props;

  const { tableData } = reportTemplates;

  return (
    <Box m={2}>
      <Grid container justifyContent="space-between">
        <Box display="flex">
          <TemplateSelect
            list={reportTemplates.templatesList}
            onAddNew={onAddNew}
            onSelect={onSelect}
            onSearchTemplate={onSearchTemplate}
          />
          <Button
            className={classes.root}
            disabled={isDisabledEdit}
            onClick={onEditClick}
            variant="contained"
            disableElevation
          >
            <Edit />
            <span>Edit & save template</span>
          </Button>
          <Button
            disabled={!tableData.length}
            onClick={onDownloadClick}
            variant="contained"
            disableElevation
          >
            <Download />
          </Button>
        </Box>
        <Box>
          <SplitCostCorrectionBtn
            onCorrectionAll={onCorrectionAll}
            onCorrectionPayout={onCorrectionPayout}
            onCorrectionRevenue={onCorrectionRevenue}
            disabled={disabledCorrection}
          />
          <Button
            disabled={disableSave(reportTemplates)}
            onClick={onApplyClick}
            color="secondary"
            variant="contained"
            data-testid="applyFiltersButton"
            sx={{
              marginLeft: 2,
            }}
          >
            <span>Apply filters</span>
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}
