// @flow
import { userService } from "../../services/user";
import {
  toggleSwitcherSaga,
  setTemplateName,
  saveTemplate,
  setTemplateGroupBySaga,
} from "../../store/reportTemplates/reportTemplates.actions";
import {
  selectCurrent,
  disableSave,
  selectToggles,
  selectCurrentSelection,
  selectCurrentHints,
} from "../../store/reportTemplates/reportTemplates.selectors";
import { MESSAGES } from "../../store/reportTemplates/reportTemplates.constants";
import type { State } from "../../store/reportTemplates/types";

const canUpdate = (template) => ((!!template.id && Number(userService.getId())) === template.createdBy);
const getUpdateTooltip = (template) => {
  if (!template.id) return MESSAGES.NOT_UPDATE_TEMPLATE_NOT_SAVED;
  if (Number(userService.getId()) !== template.createdBy) return MESSAGES.NOT_UPDATE_TEMPLATE_NOT_AUTHOR;
  return "";
};

export const mapStateToProps = ({ reportTemplates }: { reportTemplates: State }) => ({
  reportTemplates,
  disableSave: false,
  toggles: selectToggles(reportTemplates),
  groupBySelection: selectCurrentSelection(reportTemplates),
  hints: selectCurrentHints(reportTemplates),
  updateTooltip: getUpdateTooltip(selectCurrent(reportTemplates)),
  disableUpdate: !canUpdate(selectCurrent(reportTemplates)),
  disableFooterAction: disableSave(reportTemplates),
});

export const mapDispatchToProps = {
  toggleSwitcherSaga,
  setTemplateName,
  saveTemplate,
  setTemplateGroupBySaga,
};
