// @flow
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Chip } from "@mui/material";
import FilterGroup from "./FilterGroup";
import type { FilterItem, FilterValue } from "../ReportColumn/types/ReportColumn.types";

type Props = {|
  orderedFilters: FilterItem[],
  onDelete: ({ name: string, filter: FilterValue }) => void;
  clearAll: () => void;
|}
const Component = ({ orderedFilters, onDelete, clearAll }: Props) => (
  <div>
    <Chip
      label="Clear all"
      icon={<DeleteIcon />}
      color="secondary"
      onClick={clearAll}
    />
    {orderedFilters.map((filterItem: FilterItem) => (
      <Box my={2} key={filterItem.key}>
        <FilterGroup filterItem={filterItem} onDelete={onDelete} />
      </Box>
    ))}
  </div>
);

export default React.memo<Props>(Component);
