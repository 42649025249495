export const onKeyWrapper = (keys) => (fn) => {
  // eslint-disable-next-line no-underscore-dangle
  const _keys = Array.isArray(keys) ? keys : [keys];

  return function wrapped(...args) {
    const [{ key }] = args;

    if (_keys.includes(key)) fn.apply(this, args);
  };
};

const onKey = (keys) => (function wrapper(target, name, descriptor) {
  const original = descriptor.value;

  // eslint-disable-next-line no-param-reassign
  descriptor.value = onKeyWrapper(keys)(original);

  return descriptor;
});

export default onKey;
