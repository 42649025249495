// @flow
import React from "react";
import { Checkbox } from "@mui/material";

export function getFiltersExceptHidden(filters: {
  [string]: string | number
} | null = {},
hiddenFields: Array<string>) {
  if (!hiddenFields || !filters) return {};
  return Object.keys(filters).reduce((acc, key) => {
    if (!hiddenFields.includes(key)) acc[key] = filters[key];
    return acc;
  }, {});
}

export function PrefixRowComponent(props: {
  onChange: (boolean, number) => void,
  row: { [string]: string | number },
  rowIndex: number,
  isChecked: (number) => boolean,
}) {
  const {
    onChange, row: filter, rowIndex, isChecked,
  } = props;
  if (!filter || (!filter.conversion_date_from || filter.conversion_date_from !== filter.conversion_date_to)) {
    return null;
  }
  return (
    <Checkbox
      onChange={(e) => onChange(e.target.checked, rowIndex)}
      checked={isChecked(rowIndex)}
    />
  );
}
