// @flow
import {
  combineReducers, compose, createStore, applyMiddleware,
} from "redux";
import createSagaMiddleware from "redux-saga";
import notifications from "@fas/ui-framework/lib/redux/reducers/notifications";
import reportTemplates from "./reportTemplates/reportTemplates.reducer";
import costCorrection from "./costCorrection/reducer";
import mainSaga from "./reportTemplates/saga";
import type { ReportType } from "./reportTemplates/model";
import type { Actions, Dispatch, StoreType } from "./preloadState";
import preloadState from "./preloadState";

const reducers = combineReducers({
  notifications,
  reportTemplates,
  costCorrection,
});

function getStore(reportType: ReportType) {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

  // $FlowFixMe
  const store = createStore<StoreType, Actions, Dispatch>(
    reducers,
    preloadState(reportType),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );
  sagaMiddleware.run(mainSaga);
  return store;
}

export default getStore;
