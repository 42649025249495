// @flow
// eslint-disable-next-line no-unused-vars
import React from "react";
import { connect } from "react-redux";
import DateRangeSelector from "../DateRangeSelector/DateRangeSelector";
import type { Props } from "../DateRangeSelector/DateRangeSelector";
import { changeCalendarDate } from "../../store/reportTemplates/reportTemplates.actions";
import type { GroupByPreset } from "../../store/reportTemplates/model/types/index.types";
import {
  selectCurrentFilters,
  selectCurrentParams,
  selectCurrentReportModel,
} from "../../store/reportTemplates/reportTemplates.selectors";
import type { State } from "../../store/reportTemplates/types";

const mapStateToProps = ({ reportTemplates }: { reportTemplates: State }) => {
  const filters = selectCurrentFilters(reportTemplates);
  const params = selectCurrentParams(reportTemplates);
  const report = selectCurrentReportModel(reportTemplates);
  const selection = report.getGroupByList();
  const {
    range: { min = "01/01/2010", max },
    presets,
  }: GroupByPreset = selection.find(({ value }) => value === params.groupBy) || {};

  const [{ from, to }] = (params.tdsCid ? filters.conversion_date : filters.date) || [{ from: "", to: "" }];

  return {
    preset: params.period,
    from,
    to,
    presets,
    min,
    max,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeCalendarDate: (payload) => dispatch(changeCalendarDate(payload)),
});

export default connect<Props, {||}, _, _, _, _>(mapStateToProps, mapDispatchToProps)(DateRangeSelector);
