// @flow
import {
  Box, Checkbox, ListItemText, MenuItem, Select, TextField, Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React from "react";

const Text = withStyles({
  root: {
    fontWeight: 500,
  },
})(Typography);

export default ({
  transactionData,
  declineReasonValues,
  onChangePayout,
  onChangeRevenue,
  onChangeIsDeclined,
  onChangeReason,
  isErrorDeclineReason,
  isErrorRevenue,
  isErrorPayout,
}: {
  transactionData: {
    payout: number,
    revenue: number,
    isDeclined: boolean,
    declineReason: Array<string>,
  },
  declineReasonValues: Array<string>,
  onChangePayout: ((value: number) => void) | null,
  onChangeRevenue: ((value: number) => void) | null,
  onChangeIsDeclined: (value: boolean) => void,
  onChangeReason: (value: Array<string>) => void,
  isErrorDeclineReason: boolean,
  isErrorRevenue: boolean,
  isErrorPayout: boolean
}) => (
  <Box px={3} pt={3}>
    {onChangePayout && (
      <Box pb={3}>
        <Box pb={1}><Text>Payout</Text></Box>
        <TextField
          value={transactionData.payout}
          onChange={(e) => onChangePayout(parseFloat(e.target.value))}
          type="number"
          size="small"
          variant="outlined"
          placeholder="Example: 1"
          fullWidth
          error={isErrorPayout}
          data-testid="payout"
        />
      </Box>
    )}
    {onChangeRevenue && (
      <Box pb={3}>
        <Box pb={1}><Text>Revenue</Text></Box>
        <TextField
          value={transactionData.revenue}
          onChange={(e) => onChangeRevenue(parseFloat(e.target.value))}
          type="number"
          size="small"
          variant="outlined"
          placeholder="Example: 1"
          fullWidth
          error={isErrorRevenue}
          data-testid="revenue"
        />
      </Box>
    )}
    <Box pb={3}>
      <Box pb={1}><Text>Is_Declined</Text></Box>
      <Select
        margin="dense"
        fullWidth
        variant="outlined"
        value={transactionData.isDeclined}
        onChange={(e) => onChangeIsDeclined(e.target.value)}
        data-testid="isDeclined"
      >
        <MenuItem value>
          true
        </MenuItem>
        <MenuItem value={false}>
          false
        </MenuItem>
      </Select>
    </Box>
    <Box pb={3}>
      <Box pb={1}><Text>Decline Reason</Text></Box>
      <Select
        multiple
        fullWidth
        margin="dense"
        variant="outlined"
        disabled={!transactionData.isDeclined}
        value={transactionData.declineReason || []}
        renderValue={(selected) => selected.join(", ")}
        onChange={(e) => onChangeReason(e.target.value)}
        error={isErrorDeclineReason}
        data-testid="declineReason"
        MenuProps={{ "data-testid": "select-menu" }}
      >
        {declineReasonValues.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={(transactionData.declineReason || []).includes(name)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  </Box>
);
