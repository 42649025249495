import { put, select, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import dayjs from "dayjs";
import { CHANGE_CALENDAR_DATE_SAGA } from "../reportTemplates.actions-types";
import { selectCurrent, selectCurrentReportModel } from "../reportTemplates.selectors";
import {
  setPeriod, toggleCurrentDay, toggleFilters, changeOrder, clearOldReportData, setHiddenFilters,
} from "../reportTemplates.actions";
import { PERIOD } from "../reportTemplates.constants";

export function* changeCalendarDate(action) {
  const current = yield select((state) => selectCurrent(state.reportTemplates));
  const report = yield select((state) => selectCurrentReportModel(state.reportTemplates));
  const { range, preset } = action;
  const {
    params,
    filters,
    fieldsOrder,
    fieldsHidden,
  } = _.cloneDeep(current);
  const dateKey = params.tdsCid ? "conversion_date" : "date";

  const isForToday = dayjs(range.to).isSame(Date.now(), "day");
  const isYesterday = dayjs(range.to).isSame(dayjs(Date.now()).subtract(1, "day"), "day")
    && dayjs(range.from).isSame(dayjs(Date.now()).subtract(1, "day"), "day");

  if (isForToday) {
    params.currentDay = true;
  }

  if (isYesterday) {
    params.currentDay = false;
  }

  if (preset === PERIOD.custom && !isForToday) {
    params.currentDay = false;
  }

  const newFilters = {};
  Object.keys(filters).forEach((key) => {
    if (report.getFilterByKey(key).value(params)) {
      newFilters[key] = filters[key];
    }
  });

  const newFieldsOrder = fieldsOrder.filter(
    (filter) => newFilters[filter]
  );

  const newHiddenFields = fieldsHidden.filter((filter) => newFilters[filter]);

  newFilters[dateKey] = [range];

  yield put(changeOrder(newFieldsOrder));
  yield put(setHiddenFilters(newHiddenFields));
  yield put(toggleFilters(newFilters));
  yield put(setPeriod(preset));
  yield put(toggleCurrentDay(isForToday));
  yield put(clearOldReportData());
}

export function* watchChangeCalendarDate() {
  yield takeEvery(CHANGE_CALENDAR_DATE_SAGA, changeCalendarDate);
}
