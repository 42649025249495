// @flow
import React from "react";
import { Box, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { isEqual } from "lodash";
import type { FilterItem, FilterValue } from "../ReportColumn/types/ReportColumn.types";

type Props = {|
  filterItem: FilterItem;
  onDelete: ({ name: string, filter: FilterValue }) => void;
|}
const useStyle = makeStyles(() => ({
  root: {
    fontSize: "0.8rem",
    color: "#8D96AE",
  },
}));
const Component = ({ filterItem, onDelete }: Props) => {
  const classes = useStyle();

  return (
    <>
      <Box classes={classes} mb={1}>
        {filterItem.title}
      </Box>
      <Box display="flex" flexWrap="wrap">
        {filterItem.filter.map((filter: FilterValue) => (
          <Box
            maxWidth="100%"
            boxSizing="border-box"
            mb={1}
            mr={1}
            key={String(filter)}
          >
            <Chip
              label={String(filter)}
              onDelete={() => onDelete({ name: filterItem.key, filter })}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default React.memo<Props>(Component, isEqual);
