// @flow
import React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => (
  <div>
    <Box textAlign="center" component="p"> Get data for report, wait please ...</Box>
    <Box component="div" textAlign="center">
      <CircularProgress color="inherit" />
    </Box>
  </div>
);

export default Loader;
