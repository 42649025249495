// @flow
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AutoSizer, List } from "react-virtualized";
import ReactDOM from "react-dom";
import type { DropResult } from "react-beautiful-dnd";
import ReportColumns from "../ReportColumn/ReportColumns";
import type { Params } from "../../store/reportTemplates/types";
import type { FilterItem } from "../ReportColumn/types/ReportColumn.types";
import type { ReportType } from "../../store/reportTemplates/model";

type Props = {|
  orderedFilters: Array<FilterItem>,
  addFilter: ({ key: string, value: any }) => void,
  reorderFilters: (DropResult) => void,
  params: Params,
  reportType: ReportType,
  onDeleteItem: (item: FilterItem) => void,
  onChangeVisibilityItem: (item: FilterItem) => void,
|}

const FILTER_LIST_STYLE = { height: "100%" };

const styles = makeStyles((theme) => ({
  filtersListContainer: {
    padding: `${theme.spacing(2)} 0 55px`,
    background: `${theme.palette.background.darkDefault}`,
  },
  filtersList: {
    height: "100%",
    overflow: "auto",
    padding: "0",
  },
  reportColumn: {
    marginBottom: theme.spacing(2),
  },
}));

const ChoicedFiltersList = (props: Props) => {
  const classes = styles();
  const {
    orderedFilters,
    addFilter,
    reorderFilters,
    params,
    reportType,
    onDeleteItem,
    onChangeVisibilityItem,
  } = props;
  const rowHeight = 70;

  const cloneRender = (provided, snapshot, rubric) => (
    <Box sx={{ height: 70 }}>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <ReportColumns
          reportType={reportType}
          item={orderedFilters[rubric.source.index + 1]}
          addFilter={addFilter}
          params={params}
        />
      </div>
    </Box>
  );

  const rowRenderer = ({ index, key, style }) => {
    const item = orderedFilters[index + 1];
    return (
      <div key={key} style={style} data-testid={`${item.title} ValueInput`}>
        <Draggable draggableId={index.toString()} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ReportColumns
                reportType={reportType}
                item={item}
                addFilter={addFilter}
                params={params}
                onDeleteItem={onDeleteItem}
                onChangeVisibilityItem={onChangeVisibilityItem}
              />
            </div>
          )}
        </Draggable>
      </div>
    );
  };
  const dateCol = orderedFilters[0];
  dateCol.title = `${dateCol.title} (Group by ${params.groupBy})`;
  return (
    <div className={classes.filtersList}>
      <div style={FILTER_LIST_STYLE}>
        <Box mb={2} data-testid={`${orderedFilters[0].title} ValueInput`}>
          <ReportColumns
            reportType={reportType}
            item={dateCol}
            addFilter={addFilter}
            params={params}
          />
        </Box>
        <DragDropContext onDragEnd={reorderFilters}>
          <Droppable
            droppableId="droppable"
            mode="virtual"
            renderClone={cloneRender}
          >
            {(droppableProvided) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    style={{ padding: "0 5px" }}
                    height={height - 85}
                    rowHeight={rowHeight}
                    rowCount={orderedFilters.length - 1}
                    rowRenderer={rowRenderer}
                    overscanRowCount={4}
                    id="choised-filters-scroll-view"
                    ref={(ref) => {
                      // react-virtualized has no way to get the list's ref that I can so
                      // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                      if (ref) {
                        // eslint-disable-next-line react/no-find-dom-node
                        const domNode = ReactDOM.findDOMNode(ref);
                        if (domNode instanceof HTMLElement) {
                          droppableProvided.innerRef(domNode);
                        }
                        ref.forceUpdateGrid();
                      }
                    }}
                  />
                )}
              </AutoSizer>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ChoicedFiltersList;
