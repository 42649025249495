// @flow
import React from "react";
import { Box, IconButton } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

interface Props {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (newPage: number) => void;
}

const TablePaginationActions = ({
  count, onPageChange, page, rowsPerPage,
}: Props) => {
  const firstPageClick = () => onPageChange(0);

  const prevPageClick = () => onPageChange(page - 1);

  const nextPageClick = () => onPageChange(page + 1);

  const lastPageClick = () => onPageChange(Math.max(1, Math.ceil(count / rowsPerPage) - 1));

  const isFirstPage = page === 0;
  const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1;

  return (
    <Box display="flex">
      <IconButton
        disabled={isFirstPage}
        aria-label="First page"
        onClick={firstPageClick}
        size="large"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        disabled={isFirstPage}
        aria-label="Previous page"
        onClick={prevPageClick}
        size="large"
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        disabled={isLastPage}
        aria-label="Next page"
        onClick={nextPageClick}
        size="large"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        disabled={isLastPage}
        aria-label="Last page"
        onClick={lastPageClick}
        size="large"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

export default TablePaginationActions;
