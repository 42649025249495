// @flow
import type { Dictionary, TransactionData } from "./types";
import {
  COST_CORRECTION_OPEN_MODAL_SAGA,
  COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA,
  COST_CORRECTION_CLOSE_MODAL,
  COST_CORRECTION_OPEN_MODAL,
  COST_CORRECTION_SET_MODAL_VALUES,
  COST_CORRECTION_SET_TRANSACTION_DATA,
  COST_CORRECTION_START_LOADING,
  COST_CORRECTION_END_LOADING,
  CHANGE_MODAL_MODE,
  SET_IS_COST_CORRECTION_PROCESSING,
} from "./actions-types";

export type OpenModalSagaAction = {
  type: typeof COST_CORRECTION_OPEN_MODAL_SAGA,
  tds_cid?: string,
  date?: string,
  currentDay?: boolean,
};
export type UpdateTransactionDataSagaAction =
  { type: typeof COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA, value: {
      payout?: number,
      revenue?: number,
      isDeclined: boolean,
      declineReason: Array<string>,
    } };

export type Actions =
  | OpenModalSagaAction
  | UpdateTransactionDataSagaAction
  | { type: typeof COST_CORRECTION_OPEN_MODAL }
  | { type: typeof COST_CORRECTION_START_LOADING }
  | { type: typeof COST_CORRECTION_END_LOADING }
  | { type: typeof COST_CORRECTION_SET_MODAL_VALUES, value: Dictionary }
  | { type: typeof COST_CORRECTION_SET_TRANSACTION_DATA, value: TransactionData }
  | { type: typeof COST_CORRECTION_CLOSE_MODAL }
  | { type: typeof CHANGE_MODAL_MODE, value: string }
  | { type: typeof SET_IS_COST_CORRECTION_PROCESSING, value: boolean };

export const openModalSaga = (tdsCid?: string, date?: string, currentDay?: boolean): Actions => ({
  type: COST_CORRECTION_OPEN_MODAL_SAGA,
  tds_cid: tdsCid,
  date,
  currentDay,
});

export const updateTransactionDataSaga = (value: {
  payout?: number,
  revenue?: number,
  isDeclined: boolean,
  declineReason: Array<string>,
}): Actions => ({
  type: COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA,
  value,
});

export const openModal = (): Actions => ({
  type: COST_CORRECTION_OPEN_MODAL,
});

export const closeModal = (): Actions => ({
  type: COST_CORRECTION_CLOSE_MODAL,
});
export const startLoading = (): Actions => ({
  type: COST_CORRECTION_START_LOADING,
});

export const endLoading = (): Actions => ({
  type: COST_CORRECTION_END_LOADING,
});

export const setModalValues = (value: Dictionary): Actions => ({
  type: COST_CORRECTION_SET_MODAL_VALUES,
  value,
});

export const setTransactionData = (value: TransactionData): Actions => ({
  type: COST_CORRECTION_SET_TRANSACTION_DATA,
  value,
});

export const changeModalMode = (value: string): Actions => ({
  type: CHANGE_MODAL_MODE,
  value,
});

export const setIsCostCorrectionProcessing = (value: boolean): Actions => ({
  type: SET_IS_COST_CORRECTION_PROCESSING,
  value,
});
