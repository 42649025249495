// @flow
import React from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

type Props = {
  onDirectionChange: (direction: string) => void;
}

const DirectionSelector = ({ onDirectionChange }: Props) => (
  <div>
    <IconButton data-testid="top" size="small" className="direction-btn vertical to-top" onClick={() => onDirectionChange("top")}>
      <KeyboardArrowUpIcon />
    </IconButton>
    <IconButton data-testid="left" size="small" className="direction-btn horizontal to-left" onClick={() => onDirectionChange("left")}>
      <KeyboardArrowLeftIcon />
    </IconButton>
    <IconButton data-testid="right" size="small" className="direction-btn horizontal to-right" onClick={() => onDirectionChange("right")}>
      <KeyboardArrowRightIcon />
    </IconButton>
    <IconButton data-testid="bottom" size="small" className="direction-btn vertical to-bottom" onClick={() => onDirectionChange("bottom")}>
      <KeyboardArrowDownIcon />
    </IconButton>
  </div>
);

export default DirectionSelector;
