// @flow
import React from "react";

import {
  FormControlLabel,
  Switch,
  Tooltip,
  Box,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface Props {
  label: string;
  hint: string;
  checked: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const SwitchWithInfo = (props: Props) => {
  const {
    checked, label, hint, onClick, disabled = false,
  } = props;
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormControlLabel
        disabled={disabled}
        control={<Switch checked={checked} onChange={onClick} color="secondary" />}
        label={label}
      />
      <Tooltip title={hint}>
        <HelpOutlineIcon />
      </Tooltip>
    </Box>
  );
};

export default SwitchWithInfo;
