// @flow
import {
  AppBar, Box, Button, Input, Toolbar,
} from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import SplitButton from "./SplitButton";

type Props = {|
  onShow: () => void;
  onBake: () => void;
  onSave: () => void;
  onChangeName: (string) => void;
  templateName: string;
  disableSave: boolean;
  disableUpdate: boolean,
  onUpdate: () => void,
  disabled: boolean,
  updateTooltip: string,
|}

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.borderColor}`,
  },
}));

function ModalFooter(props: Props) {
  const {
    onShow,
    onBake,
    onSave,
    onChangeName,
    templateName,
    disableSave,
    disableUpdate,
    onUpdate,
    disabled,
    updateTooltip,
  } = props;
  const classes = useStyles();
  const handleChange = (event) => {
    onChangeName(event.target.value);
  };
  return (
    <AppBar classes={classes} position="relative" color="secondary">
      <Toolbar>
        <Box flexGrow={1} p={1}>
          <Input
            size="small"
            variant="outlined"
            fullWidth
            value={templateName}
            onChange={handleChange}
            data-testid="nameInput"
          />
        </Box>
        <Box p={1} data-testid="splitButton">
          <SplitButton
            updateTooltip={updateTooltip}
            onSave={onSave}
            disableSave={disableSave}
            onUpdate={onUpdate}
            disableUpdate={disableUpdate}
            disabled={disabled}
          />
        </Box>
        <Box display="flex" alignItems="center" flexGrow={1} flexDirection="row-reverse">
          <Box p={1}>
            <Button
              disabled={disabled}
              variant="outlined"
              color="secondary"
              onClick={onShow}
              data-testid="showReportButton"
            >
              Show Report
            </Button>
          </Box>
          <Box p={1}>
            <Button
              disabled={disabled}
              variant="contained"
              color="secondary"
              onClick={onBake}
              data-testid="bakeReportButton"
            >
              Bake Report
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo<Props>(ModalFooter);
