import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import _ from "lodash";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { REPORT_TEMPLATE_SHOW_REPORT_SAGA } from "../reportTemplates.actions-types";
import {
  errorFetchReport, startFetchReport, successShowReport, warnShowReport, clearOldReportData,
} from "../reportTemplates.actions";
import { MESSAGES } from "../reportTemplates.constants";

export function* showReport() {
  try {
    yield put(clearOldReportData());
    yield put(startFetchReport());

    const template = yield select((state) => state.reportTemplates.current);

    const response = yield call(api.getReport, _.cloneDeep(template));
    yield put(addNotification({ message: MESSAGES.SUCCESS_SHOW_REPORT, severity: "success" }));
    yield put(successShowReport(response?.data?.report || []));
  }
  catch (error) {
    if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
      yield put(addNotification({ message: MESSAGES.WARN_SHOW_REPORT, severity: "warning" }));
      yield put(warnShowReport());
    }
    else {
      yield put(addNotification({
        message: error.errorMessage || MESSAGES.ERROR_SHOW_REPORT,
        severity: "error",
      }));
      yield put(errorFetchReport({ isBaking: error?.response?.data?.retryInBakery }));
    }
  }
}

export function* watchShowReport() {
  yield takeEvery(REPORT_TEMPLATE_SHOW_REPORT_SAGA, showReport);
}
