export default (baseUrl, timeout = 30000) => ({
  baseUrl,
  timeout,
  appLinks: {
    dmp: "https://rel-admin-dmp.insigit.com/",
    jus: "https://jus.rel.insigit.com/",
    cpa: "https://cpa-admin.rel.insigit.com/",
    webPush: "https://webpush.rel.noclef.com/",
    adsBox: "https://adsbox.rel.insigit.com/",
    reports: "https://reports.rel.insigit.com/managersReport",
    crm: "https://crm.rel.insigit.com/",
  },
  endpoints: {
    get: {
      apiKey: `${baseUrl}/api/login`,
      templateList: `${baseUrl}/api/v1/report/templates/list`,
      getTemplate: `${baseUrl}/api/v1/report/templates`,
      costCorrectionTransactionData: `${baseUrl}/api/v1/report/transactions/{tds_cid}`,
      costCorrectionDictionary: `${baseUrl}/api/v1/report/getDictionary`,
      signOut: `${baseUrl}/logout`,
    },
    post: {
      updateTemplate: `${baseUrl}/api/v1/report/templates`,
      getReport: `${baseUrl}/api/v1/report/getReport`,
      bakeReport: `${baseUrl}/api/v1/report/bakeReport`,
    },
    put: {
      createTemplate: `${baseUrl}/api/v1/report/templates`,
      costCorrectionUpdateTransactionData: `${baseUrl}/api/v1/report/transactions`,
    },
    delete: {
      deleteTemplate: `${baseUrl}/api/v1/report/templates`,
    },
  },
});
