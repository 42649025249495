// @flow

export const COST_CORRECTION_OPEN_MODAL_SAGA: "COST_CORRECTION_OPEN_MODAL_SAGA" = "COST_CORRECTION_OPEN_MODAL_SAGA";
export const COST_CORRECTION_OPEN_MODAL: "COST_CORRECTION_OPEN_MODAL" = "COST_CORRECTION_OPEN_MODAL";
export const COST_CORRECTION_SET_MODAL_VALUES: "COST_CORRECTION_SET_MODAL_VALUES" = "COST_CORRECTION_SET_MODAL_VALUES";
export const COST_CORRECTION_SET_TRANSACTION_DATA: "COST_CORRECTION_SET_TRANSACTION_DATA" = "COST_CORRECTION_SET_TRANSACTION_DATA";
export const COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA: "COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA" = "COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA";
export const COST_CORRECTION_CLOSE_MODAL: "COST_CORRECTION_CLOSE_MODAL" = "COST_CORRECTION_CLOSE_MODAL";
export const COST_CORRECTION_START_LOADING: "COST_CORRECTION_START_LOADING" = "COST_CORRECTION_START_LOADING";
export const COST_CORRECTION_END_LOADING: "COST_CORRECTION_END_LOADING" = "COST_CORRECTION_END_LOADING";
export const CHANGE_MODAL_MODE: "CHANGE_MODAL_MODE" = "CHANGE_MODAL_MODE";
export const SET_IS_COST_CORRECTION_PROCESSING: "SET_IS_COST_CORRECTION_PROCESSING" = "SET_IS_COST_CORRECTION_PROCESSING";
