// @flow
import * as React from "react";
import { AccessDenied } from "@fas/ui-core";
import { userService } from "../../services/user";

interface Props {
  aclRule: string,
  children: React.Node,
}

const PrivateComponent = (props: Props) => {
  const { aclRule, children } = props;
  if (userService.can([aclRule])) {
    return children;
  }
  return (<AccessDenied />);
};

export default PrivateComponent;
