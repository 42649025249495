/* eslint-disable import/max-dependencies */
// @flow
import React, { Component } from "react";
import {
  Grid, Typography, Box, Tooltip,
} from "@mui/material";
import { NavigationList, ListWithGroups } from "@fas/ui-core";
import withStyles from "@mui/styles/withStyles";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import autobind from "react-autobind";
import { connect } from "react-redux";
import FilteringValues from "../FilteringValues/FilteringValues";
import { mapDispatchToProps, mapStateToProps } from "./store-connector";
import type { Props } from "./types/BodyContainer.types";
import type { FilterItem } from "../ReportColumn/types/ReportColumn.types";
import { ORDER_EXCLUDE_FILTERS } from "../../store/reportTemplates/reportTemplates.constants";
import ChoicedFiltersList from "../ChoicedFiltersList";

interface State {
  selectedGroup: string;
  isNavigated: boolean;
}

class BodyContainer extends Component<{
  ...Props,
  classes: Object
}, State> {
  constructor() {
    super();
    this.state = {
      selectedGroup: "",
      isNavigated: false,
    };
    autobind(this, "onFilterListClick", "onDeleteItemClick", "onChangeVisibilityItemClick", "selectGroup", "onScrollChange", "onFilterDelete");
  }

  onFilterListClick({
    groupIndex,
    index: filterIndex,
    value,
  }: { groupName: string, groupIndex: number, title: string, index: number, value: boolean}) {
    const { toggleFilter, groups } = this.props;
    toggleFilter({ filterName: groups[groupIndex].filters[filterIndex].filterKey, value });
  }

  onDeleteItemClick(item: FilterItem) {
    const { toggleFilter } = this.props;
    const filterName = item.key;
    const value = false;
    toggleFilter({ filterName, value });
  }

  onChangeVisibilityItemClick(item: FilterItem) {
    const { setHiddenFilters, orderedFilters } = this.props;

    const fieldsHidden = orderedFilters.reduce((acc, field) => {
      const value = (field.key === item.key) ? !field.isVisible : field.isVisible;
      if (!value) acc.push(field.key);
      return acc;
    }, []);
    setHiddenFilters(fieldsHidden);
  }

  selectGroup(group: string) {
    this.setState({ selectedGroup: group, isNavigated: true });
  }

  onScrollChange() {
    const { selectedGroup, isNavigated } = this.state;
    if (selectedGroup && !isNavigated) {
      this.setState({ selectedGroup: "" });
    }
    else {
      this.setState({ isNavigated: false });
    }
  }

  onFilterDelete({ name, filter }) {
    const { deleteFilter } = this.props;
    deleteFilter({ key: name, value: filter });
  }

  render() {
    const {
      clearFilters,
      groups,
      reorderFilters,
      isAllSelected,
      toggleAll,
      orderedFilters,
      groupNamesList,
      classes,
      reportTemplates: {
        current: { params },
        reportType,
      },
      addFilter,
    } = this.props;
    const { selectedGroup } = this.state;

    const hiddenFieldCount = orderedFilters.filter((item) => !item.isVisible).length;
    const hiddenFieldTooltip = "Selected filters will be applied even when the column is not displayed.";

    return (
      <Grid container className={classes.bodyContainer}>
        <Grid item xs={3} className={classes.navigatonListContainer}>
          <NavigationList
            list={groupNamesList}
            selected={selectedGroup}
            onChange={this.selectGroup}
          />
        </Grid>

        <Grid
          item
          xs={3}
          className={classes.groupList}
        >
          <ListWithGroups
            isAllSelected={isAllSelected}
            toggleAll={toggleAll}
            groups={groups}
            scrollTo={selectedGroup}
            onItemClick={this.onFilterListClick}
            onScroll={this.onScrollChange}
            rowHeight={50}
          />
        </Grid>

        <Grid className={classes.filtersListContainer} item xs={3}>
          <Box p={2} className={classes.reportColumnTitle}>
            <Typography variant="subtitle2" gutterBottom>
              Report Columns
            </Typography>
            {!!hiddenFieldCount && (
              <Box display="flex" alignItems="center">
                <Tooltip title={hiddenFieldTooltip}>
                  <VisibilityOffIcon />
                </Tooltip>
                <Typography>{hiddenFieldCount}</Typography>
              </Box>
            )}
          </Box>
          <ChoicedFiltersList
            reportType={reportType}
            orderedFilters={orderedFilters}
            addFilter={addFilter}
            reorderFilters={reorderFilters}
            params={params}
            onDeleteItem={this.onDeleteItemClick}
            onChangeVisibilityItem={this.onChangeVisibilityItemClick}
          />
        </Grid>

        <Grid className={classes.filtersValuesContainer} item xs={3}>
          <Box py={2}>
            <Typography variant="subtitle2" gutterBottom>
              Filtering
            </Typography>
          </Box>
          <Grid className={classes.filtersValues}>
            <FilteringValues
              orderedFilters={
                orderedFilters.filter(({ key, filter }) => !ORDER_EXCLUDE_FILTERS.includes(key) && filter.length)
              }
              onDelete={this.onFilterDelete}
              clearAll={clearFilters}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  navigatonListContainer: {
    padding: `${theme.spacing(2)} 0 0`,
    borderRight: `1px solid ${theme.palette.borderColor}`,
    height: "100%",
  },
  groupList: {
    padding: `${theme.spacing(2)} 0 0`,
  },
  filtersListContainer: {
    padding: `${theme.spacing(2)} 0 55px`,
    background: `${theme.palette.background.darkDefault}`,
  },
  filtersList: {
    height: "100%",
    overflow: "auto",
    padding: "0",
  },
  reportColumn: {
    marginBottom: theme.spacing(2),
  },
  filtersValuesContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)} 0`,
    background: `${theme.palette.background.paper}`,
    height: "100%",
    flexDirection: "column",
    display: "flex",
  },
  filtersValues: {
    height: "100%",
    overflow: "scroll",
  },
  bodyContainer: {
    height: "100%",
    overflow: "hidden",
  },
  redText: {
    color: `${theme.palette.text.pink}`,
  },
  reportColumnTitle: {
    display: "flex",
    "justify-content": "space-between",
    "align-items": "center",
  },
});

export default connect<Props, {||}, _, _, _, _>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BodyContainer));
