// @flow
import dayjs from "dayjs";
import en from "dayjs/locale/en-au";

dayjs.locale(en);

export const RESET_EXCLUDE_FILTERS = ["date", "conversion_date"];

export const ORDER_EXCLUDE_FILTERS = ["date", "conversion_date"];

/**
 * @deprecated you must be used Filter model
 * @example FilterModel.disabled(params)
 * @see FilterModel
 */
export const DISABLED_FIELDS = [
  "date",
  "conversion_date",
];

export const MESSAGES = {
  ERROR_UPDATE_TRANSACTION_DATA: "Can’t update transaction data.",
  SUCCESS_UPDATE_TRANSACTION_DATA: "Transaction data successfully update.",
  ERROR_GET_TRANSACTION_DATA: "Can’t load transaction data.",
  SUCCESS_GET_TRANSACTION_DATA: "Transaction data successfully loaded.",
  ERROR_TEMPLATES_LIST: "Can’t load list of templates",
  ERROR_GET_TEMPLATE: "Can’t load report",
  SUCCESS_GET_TEMPLATE: "Template successfully loaded",
  ERROR_DELETE_TEMPLATE: "Can’t delete template",
  SUCCESS_DELETE_TEMPLATE: "Template has been successfully deleted",
  WARN_SHOW_REPORT: "Timeout while getting your report. Try to bake report instead.",
  ERROR_SHOW_REPORT: "Can’t show report",
  SUCCESS_SHOW_REPORT: "Report successfully generated",
  SUCCESS_BAKE_REPORT: "Report has been sent to bakery, you will receive it soon to your email",
  ERROR_BAKE_REPORT: "Can’t bake report",
  ERROR_SAVE_TEMPLATE: "Can’t save template",
  ERROR_SAVE_DUPLICATE_TEMPLATE: "You can't save the template because we already have a template with that name. Try a different name.",
  SUCCESS_SAVE_TEMPLATE: "Template has been successfully saved",
  NOT_UPDATE_TEMPLATE_NOT_SAVED: "Nothing to update, you should create template firstly.",
  NOT_UPDATE_TEMPLATE_NOT_AUTHOR: "You can’t update template because you’re not it’s author.",
};

export const PERIOD = {
  custom: "custom",
  today: "today",
  yesterday: "yesterday",
  last3days: "last3days",
  lastweek: "lastweek",
  lastmonth: "lastmonth",
  thismonth: "thismonth",
  thisweek: "thisweek",
  thisyear: "thisyear",
  from2020: "from2020",
};
export type Period = $Keys<typeof PERIOD>;

export type DateRangePreset = {
  label: string,
  value: Period,
  start:string,
  end:string,
}

export const PRESETS: { [string]: DateRangePreset } = {
  today: {
    label: "Today",
    value: PERIOD.today,
    start: dayjs().startOf("d").format("YYYY-MM-DD"),
    end: dayjs().endOf("d").format("YYYY-MM-DD"),
  },
  yesterday: {
    label: "Yesterday",
    value: PERIOD.yesterday,
    start: dayjs().startOf("d").subtract(1, "d").format("YYYY-MM-DD"),
    end: dayjs().endOf("d").subtract(1, "d").format("YYYY-MM-DD"),
  },
  last3days: {
    label: "Last 3 days",
    value: PERIOD.last3days,
    start: dayjs().subtract(3, "d").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  },
  lastweek: {
    label: "Last week",
    value: PERIOD.lastweek,
    start: dayjs().subtract(1, "w").startOf("w")
      .format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "w").endOf("w")
      .format("YYYY-MM-DD"),
  },
  lastmonth: {
    label: "Last month",
    value: PERIOD.lastmonth,
    start: dayjs().subtract(1, "M").startOf("M").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "M").endOf("M").format("YYYY-MM-DD"),
  },
  thismonth: {
    label: "This month",
    value: PERIOD.thismonth,
    start: dayjs().startOf("M").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  thisweek: {
    label: "This week",
    value: PERIOD.thisweek,
    start: dayjs().startOf("w").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  thisyear: {
    label: "This year",
    value: PERIOD.thisyear,
    start: dayjs().startOf("y").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  from2020: {
    label: "Starting from 2020",
    value: PERIOD.from2020,
    start: dayjs("2020-01-01").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
};

export function getPresetByPeriod(presets: DateRangePreset[], period: Period): DateRangePreset {
  const customPreset = {
    label: "Custom",
    value: PERIOD.custom,
    start: dayjs().format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  };
  return presets.find((item) => item.value === period) || customPreset;
}

export const GROUP_BY_LIST = {
  hour: "hour",
  day: "day",
  week: "week",
  month: "month",
  year: "year",
};
export type GroupBy = $Keys<typeof GROUP_BY_LIST>;
