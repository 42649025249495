// @flow

import { Box, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import type { TransactionData } from "../../store/costCorrection/types";

const Text = withStyles({
  root: {
    fontWeight: 500,
  },
})(Typography);

export default ({ transactionData }: {transactionData: TransactionData}) => (
  <Box p={3}>
    <Box pb={2}>
      <Typography variant="h6">CURRENT VALUES</Typography>
    </Box>
    <Box display="flex">
      <Box pr={6}>
        <Text>
          Payout:
          {" "}
          {String(transactionData.payout)}
        </Text>
      </Box>
      <Box pr={6}>
        <Text>
          Cost to Auto-declines:
          {" "}
          {String(transactionData.costValue)}
        </Text>
      </Box>
      <Box pr={6}>
        <Text>
          Revenue:
          {" "}
          {String(transactionData.revenue)}
        </Text>
      </Box>
      <Box pr={6}>
        <Text>
          Is_Declined:
          {" "}
          {String(transactionData.isDeclined)}
        </Text>
      </Box>
      <div />
    </Box>
    <Box pt={1}>
      <Text>
        Decline Reason:
        {" "}
        {(transactionData.declineReason || []).join(", ")}
      </Text>
    </Box>
  </Box>
);
