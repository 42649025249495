// @flow
import type { Params } from "../../store/reportTemplates/types";
import type { Row } from "./types/ReportTable.types";
import { getReportModel } from "../../store/reportTemplates/model";
import type { ReportType } from "../../store/reportTemplates/model";

const defaultValue = (v) => (typeof v === "number" ? v : (v || ""));

const desc = (a, b, orderBy) => {
  if (b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === null) {
    return 1;
  }
  let s1: any = defaultValue(a[orderBy]);
  let s2: any = defaultValue(b[orderBy]);

  if (typeof a[orderBy] === "string") {
    s1 = a[orderBy].toLowerCase();
  }
  if (typeof b[orderBy] === "string") {
    s2 = b[orderBy].toLowerCase();
  }

  if (s1 < s2) return 1;
  if (s1 > s2) return -1;
  return 0;
};

export function getSorting(order: "desc" | "asc", orderBy: string) {
  type Item = { [key: string]: string | number | void | null };
  return order === "desc"
    ? (a: Item, b: Item) => desc(a, b, orderBy)
    : (a: Item, b: Item) => -desc(a, b, orderBy);
}

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number): T[] {
  type Item = [T, number];
  const stabilizedThis: Array<Item> = array.map((el: T, index: number) => ([el, index]));
  stabilizedThis.sort((a: Item, b: Item) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: Item) => el[0]);
}

export function transformOrderBy(orderBy: string, params: Params, reportType: ReportType) {
  return getReportModel(reportType).getFilterByKey(orderBy).getSortLabel(params);
}

export function getPreparedTableData(tableData: Row[], heading: Array<string>, reportType: ReportType, params: Params) {
  // $FlowFixMe
  return tableData.map((row) => heading.reduce((acc, col) => {
    const data = getReportModel(reportType)
      .getFilterByKey(col)
      .prepareData(row, params);

    acc[col] = Array.isArray(data) ? data.join(",") : data;
    return acc;
  }, {}));
}

export function convertTableDataToCsv(data: Array<Object>, columns: Array<string>, reportType: ReportType) {
  const headers = columns.map((col) => getReportModel(reportType).getFilterByKey(col).title).join(",");
  const rows = data.map((obj) => columns.map((col) => obj[col]).join(",")).join("\n");
  return `${headers}\n${rows}`;
}
