// @flow
import dayjs from "dayjs";
import React from "react";
import { Box } from "@mui/material";
import DateRangePickerV2 from "@fas/ui-core/lib/DateRangePickerV2";
import type { DateRangePreset } from "../../store/reportTemplates/reportTemplates.constants";
import { PERIOD } from "../../store/reportTemplates/reportTemplates.constants";

export type Props = {
  from: string;
  to: string;
  preset: string;
  presets: DateRangePreset[];
  min?: string;
  max?: string;
  changeCalendarDate: ({
    range: { from: string, to: string },
    preset: string,
  }) => void;
}

const DateRangeSelector = ({
  from, to, preset, changeCalendarDate, min, max, presets,
}: Props) => {
  const handleSelect = (e) => {
    const { startDate, endDate, preset: newPreset = PERIOD.custom } = e;
    changeCalendarDate({
      range: {
        from: dayjs(startDate).format("YYYY-MM-DD"),
        to: dayjs(endDate).format("YYYY-MM-DD"),
      },
      preset: newPreset,
    });
  };

  return (
    <Box position="relative" data-testid="datapicker" fontSize="small" color="text.primary">
      <DateRangePickerV2
        presets={((presets: Object[]): {...DateRangePreset, value: string}[])}
        onChangeDate={handleSelect}
        startDate={from}
        endDate={to}
        minDate={min}
        maxDate={max}
        currentPreset={preset}
        views={["year", "month", "day"]}
      />
    </Box>
  );
};
DateRangeSelector.defaultProps = {
  min: undefined,
  max: undefined,
};
export default React.memo<Props>(DateRangeSelector);
