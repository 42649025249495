// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getActiveFieldsOrder, getOrderedSelectedFilters } from "../../store/reportTemplates/reportTemplates.utils";
import { userService } from "../../services/user";
import ActionRow from "./ReportActionRow";
import CreateReportModal from "../CreateReportModal";
import Loader from "../Loader";
import ReportTable from "../ReportTable";
import { getFiltersExceptHidden, PrefixRowComponent } from "./utils";
import ApproveDialog from "../ApproveDialog";
import CostCorrectionFormContainer from "../CostCorrectionFormContainer/CostCorrectionFormContainer";
import { selectCurrentSelection } from "../../store/reportTemplates/reportTemplates.selectors";
import {
  addFilter,
  addNewTemplate,
  bakeReport,
  deleteFilter,
  selectTemplate,
  selectTemplateById,
  setTemplateGroupBySaga,
  showReport,
  updateTemplatesList,
  changeCheckedList,
  changeTableData,
} from "../../store/reportTemplates/reportTemplates.actions";
import type { Template } from "../../store/reportTemplates/types";
import { openModalSaga, changeModalMode } from "../../store/costCorrection/actions";
import { getPreparedTableData, convertTableDataToCsv } from "../ReportTable/utils";

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    background: theme.palette.background.default,
  },
  root: {
    background: theme.palette.background.paper,
    color: "rgba(0, 0, 0, 0.87)",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: `1px solid ${theme.palette.borderColor}`,
    fontWeight: 400,
    "&:hover": {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
    },
  },
}));
// eslint-disable-next-line complexity
export default function ReportContainer() {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();
  const dispatch: <A>(A) => A = useDispatch();
  const reportTemplates = useSelector((state) => state.reportTemplates);
  const groupBySelection = useSelector((state) => selectCurrentSelection(state.reportTemplates));

  const {
    current,
    tableData,
    tableDataTotal,
    isFetchingData,
    isBaking,
    reportType,
    checkedList,
  } = reportTemplates;

  useEffect(() => {
    if (id) {
      dispatch(selectTemplateById(id));
    }
    dispatch(updateTemplatesList());
  }, [id]);

  const choicedFilters = getActiveFieldsOrder({
    fieldsOrder: current && current.fieldsOrder,
    newActiveList: current && Object.keys(current.filters),
  });
  const filters = getOrderedSelectedFilters({
    orderList: choicedFilters,
    filersList: current && current.filters,
  });
  const fieldsHidden = (current && current.fieldsHidden) || [];
  const filtersExceptHidden = getFiltersExceptHidden(filters, fieldsHidden);

  const costCorrectionGroupBy = ["day"];
  const costCorrectionCanUpdatePermission = [
    { path: "/api/v1/report/transactions", method: "PUT" },
  ];

  const canUpdateTransaction: boolean = Boolean(filters
    && "tds_cid" in filters
    && "conversion_date" in filters
    && costCorrectionGroupBy.includes(current.params.groupBy)
    && userService.can(costCorrectionCanUpdatePermission));

  const isCheckedAll = Boolean(checkedList.length && (checkedList.length === tableData.length));
  const handleCheckAll = (status) => {
    if (status) {
      dispatch(changeCheckedList(tableData.map((j, i) => i)));
    }
    else {
      dispatch(changeCheckedList([]));
    }
  };

  const handleCheckboxClick = (status, rowIndex) => {
    if (status) {
      dispatch(changeCheckedList([...checkedList, rowIndex]));
    }
    else {
      dispatch(changeCheckedList(checkedList.filter((i) => rowIndex !== i)));
    }
  };

  const checkStatusCheckbox = (rowIndex) => checkedList.includes(rowIndex);
  const PrefixRowComponentUpdated = canUpdateTransaction ? (p: {
    row: { [string]: string | number },
    rowIndex: number,
  }) => (
    <PrefixRowComponent
      {...p}
      isChecked={checkStatusCheckbox}
      onChange={handleCheckboxClick}
    />
  ) : null;

  // handlers
  const handleAddTemplate = () => {
    dispatch(addNewTemplate());
    setIsEditing(true);
  };
  const handleSelectTemplate = (item: Template) => {
    dispatch(selectTemplate(item));
    setIsEditing(true);
  };
  const handleSearchTemplate = (value: string) => {
    dispatch(updateTemplatesList({ searchStr: value }));
  };
  const handleEditTemplate = () => {
    setIsEditing(true);
  };
  const handleShowReport = () => {
    dispatch(showReport());
    setIsEditing(false);
  };
  const handleCloseModal = () => {
    setIsEditing(false);
  };
  const handleBakeReport = () => {
    dispatch(bakeReport());
    setIsEditing(false);
  };
  const handleDeleteFilter = (val) => {
    dispatch(deleteFilter(val));
  };
  const handleFilterAdd = (val) => {
    dispatch(addFilter(val));
  };
  const handleChangeGroupBy = (val) => {
    dispatch(setTemplateGroupBySaga(val));
  };

  const handleCorrectionOpen = (type: string) => {
    dispatch(changeModalMode(type));
    if (checkedList.length === 1) {
      const {
        tds_cid: tdsCid,
        conversion_date_from: conversionDateFrom,
      } = tableData[checkedList[0]];
      dispatch(openModalSaga(tdsCid, conversionDateFrom, current?.params?.currentDay));
    }
    else {
      dispatch(openModalSaga());
    }
  };

  const handleChangeTableData = (data) => {
    dispatch(changeTableData(data));
  };

  const handleDownloadTemplate = () => {
    const heading = Object.keys(filtersExceptHidden);
    const preparedTableData = getPreparedTableData(tableData, heading, reportType, current.params);
    const filename = `report_${Date.now()}.csv`;

    const csv = convertTableDataToCsv(preparedTableData, heading, reportType);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  };

  return (
    <div className={classes.pageWrapper}>
      <ActionRow
        reportTemplates={reportTemplates}
        onAddNew={handleAddTemplate}
        onSelect={handleSelectTemplate}
        onSearchTemplate={handleSearchTemplate}
        classes={classes}
        isDisabledEdit={!filters}
        onEditClick={handleEditTemplate}
        onDownloadClick={handleDownloadTemplate}
        onApplyClick={handleShowReport}
        onCorrectionAll={() => handleCorrectionOpen("costCorrectionAll")}
        disabledCorrection={checkedList.length === 0}
        onCorrectionPayout={() => handleCorrectionOpen("correctionPayout")}
        onCorrectionRevenue={() => handleCorrectionOpen("correctionRevenue")}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isEditing}
        onClose={handleCloseModal}
      >
        <CreateReportModal
          onClose={handleCloseModal}
          onShowReport={handleShowReport}
          onBakeReport={handleBakeReport}
        />
      </Dialog>
      {isFetchingData && <Loader />}
      {!isEditing && !isFetchingData && tableData && filters && (
        <ReportTable
          reportType={reportType}
          groupBySelection={groupBySelection}
          rows={tableData}
          tableDataTotal={tableDataTotal}
          filters={filtersExceptHidden}
          PrefixRowComponent={PrefixRowComponentUpdated}
          isBaking={isBaking}
          onFilterDelete={handleDeleteFilter}
          onFilterAdd={handleFilterAdd}
          bakeReport={handleBakeReport}
          params={(current && current.params) || {}}
          selectGroupBy={handleChangeGroupBy}
          onCheckAll={handleCheckAll}
          isCheckedAll={isCheckedAll}
          showCheckAll={canUpdateTransaction}
          onChangeTableData={handleChangeTableData}
        />
      )}
      {!isFetchingData && tableData && !filters && (
        <ApproveDialog text="Choice template or setup some filters to get report." />
      )}
      <CostCorrectionFormContainer checkedList={checkedList} />
    </div>
  );
}
