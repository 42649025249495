// @flow
import React, { type StatelessFunctionalComponent } from "react";
import environment from "environment";
import type { Url, CollapseItem, Breadcrumb } from "@fas/ui-core/lib/AppNavbar";
import { AppNavbar } from "@fas/ui-core";
import { useLocation } from "react-router-dom";
import { userService } from "../../services/user";
import { userApiService } from "../../services/userApi";
import getBreadcrumbs from "./breadcrumbsMap";
import appLinks from "./appLinks";
import type { Link } from "./types/AppHeader.types";

const menuLinks: Link[] = [
  {
    url: "/managersReport",
    text: "Admin reports for managers",
    permissions: [
      { path: "/api/v1/report/getReport", method: "POST" },
      { path: "/api/v1/report/bakeReport", method: "POST" },
      "/managersReport",
    ],
  },
  {
    url: "/accountantsReport",
    text: "Admin reports for accountants",
    permissions: [
      { path: "/api/v1/report/getReport", method: "POST" },
      { path: "/api/v1/report/bakeReport", method: "POST" },
      "/accountantsReport",
    ],
  },
];

const AppHeader: StatelessFunctionalComponent<{}> = () => {
  const location = useLocation();

  const filteredLinks: Array<Url | CollapseItem> = menuLinks.filter(({ permissions }) => userService.can(permissions))
    .map(({ url, text }) => ({ url, text }));

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(location.pathname);

  const handleLogout = () => {
    userApiService.logout(environment.endpoints.get.signOut).then(() => window.location.reload());
  };

  return (
    <AppNavbar
      appName="Admin reports"
      menuLinks={filteredLinks}
      appLinks={appLinks}
      breadcrumbs={breadcrumbs}
      onLogout={handleLogout}
    />
  );
};

export default AppHeader;
