import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { selectTemplate, updateTemplatesList } from "../reportTemplates.actions";
import { REPORT_TEMPLATE_SAVE_SAGA } from "../reportTemplates.actions-types";
import { MESSAGES } from "../reportTemplates.constants";

export function* saveTemplate({ asNewTemplate }) {
  try {
    yield put({ type: "START_SAVE_TEMPLATE" });

    const template = yield select((state) => state.reportTemplates.current);
    if (template.id && !asNewTemplate) {
      yield call(api.updateTemplate, template);
    }
    else {
      const savedTemplate = yield call(api.createTemplate, template);
      yield put(selectTemplate(savedTemplate));
    }

    yield put({ type: "SUCCESS_SAVE_TEMPLATE" });
    yield put(addNotification({ message: MESSAGES.SUCCESS_SAVE_TEMPLATE, severity: "success" }));

    yield put(updateTemplatesList());
  }
  catch (error) {
    yield put(addNotification({ message: getErrorMessage(error.errorMessage), severity: "error" }));
    yield put({ type: "ERROR_SAVE_TEMPLATE", error });
  }
}
const getErrorMessage = (message) => {
  if (!message) return MESSAGES.ERROR_SAVE_TEMPLATE;
  return message.includes("duplicate key error collection") ? MESSAGES.ERROR_SAVE_DUPLICATE_TEMPLATE : message;
};

export function* watchCreateTemplate() {
  yield takeEvery(REPORT_TEMPLATE_SAVE_SAGA, saveTemplate);
}
