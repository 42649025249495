// @flow
import {
  keys, map, omit, orderBy, pick,
} from "lodash";
import type { State, Template } from "./types";
import FilterGroup from "./SelectionGroupFactory/FilterGroup.types";
import Filter from "./SelectionGroupFactory/Filter.types";
import { getReportModel } from "./model";
import type { SelectionItem, SelectionItemGroup } from "../../components/BodyContainer/types/BodyContainer.types";
import Report from "./model/Report";
import type { Hints, Toggle } from "./model/types/index.types";
import type { FilterItem } from "../../components/ReportColumn/types/ReportColumn.types";
import type { GroupBy } from "./reportTemplates.constants";

export const selectCurrent = ({ current }: State): Template => ({
  ...current,
});

export const selectCurrentFilters = (state: State) => selectCurrent(state).filters;

export const selectCurrentFiltersWithout = (fields: string | string[]) => (state: State) => {
  const filters = selectCurrentFilters(state);
  return omit(filters, fields);
};

export const selectCurrentParams = (state: State) => selectCurrent(state).params;

export const selectActiveFilters = (state: State) => {
  const { filters } = selectCurrent(state);
  const selector = {};
  for (const key in filters) {
    if (filters[key]) {
      selector[key] = filters[key];
    }
  }
  return selector;
};

export const selectEditableFilters = (state: State) => {
  const activeKeys = keys(selectActiveFilters(state));
  const filters = selectCurrentFilters(state);

  // $FlowFixMe
  return pick(filters, activeKeys);
};

export const selectOrderedFilters = (state: State): FilterItem[] => {
  const editableFilters = selectEditableFilters(state);
  const { fieldsOrder: order, fieldsHidden } = selectCurrent(state);
  const report = selectCurrentReportModel(state);
  return orderBy(
    map(editableFilters, (filter, key) => ({
      filter,
      key,
      title: report.getFilterByKey(key).getTitle(),
      isVisible: (!fieldsHidden || !fieldsHidden.includes(key)) || false,
    })),
    ({ key }) => order.indexOf(key)
  );
};

export const selectGroupedFilters = (state: State): SelectionItemGroup[] => {
  const { filters, params } = selectCurrent(state);
  const mapSelectedFilter = (filter: Filter): SelectionItem => ({
    title: filter.getTitleWithGroupBy(params),
    filterKey: filter.filterKey,
    isDisabled: filter.disabled(params),
    selected: filter.value(params) && Boolean(filters[filter.filterKey]),
  });
  return selectCurrentReportModel(state)
    .getFilterGroups()
    .filterGroups
    .map<SelectionItemGroup>((group: FilterGroup) => ({
      title: group.title,
      filters: group.filters.map(mapSelectedFilter),
    }));
};

export const selectIsAllSelected = (state: State): boolean => {
  const { filters, params } = selectCurrent(state);
  const allowedFilterTitles = selectCurrentReportModel(state).getAllowedFilterKeys(params);
  return !allowedFilterTitles.filter((key) => !filters[key]).length;
};

export const disableSave = (state: State): boolean => {
  const { filters, params } = selectCurrent(state);
  return !(filters && params && selectCurrentReportModel(state).canExecute(params, filters));
};

export const selectGroupNamesList = (state: State) => selectCurrentReportModel(state)
  .getFilterGroups().getGroupNamesList(selectCurrentParams(state));

export const selectToggles = (state: State): Toggle[] => selectCurrentReportModel(state).getToggles();

type GroupByList = {label: string, value: GroupBy }[];
export const selectCurrentSelection = (state: State): GroupByList => selectCurrentReportModel(state)
  .getGroupByList().map(({ label, value }) => ({ label, value }));

export const selectCurrentHints = (state: State): Hints => selectCurrentReportModel(state).getHints();

export const selectCurrentReportModel = (state: State): Report => getReportModel(state.reportType);
