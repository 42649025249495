import { call, put, takeEvery } from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { selectTemplate } from "../reportTemplates.actions";
import { REPORT_TEMPLATES_GET_TEMPLATE_SAGA } from "../reportTemplates.actions-types";
import { MESSAGES } from "../reportTemplates.constants";

export function* getTemplate(action) {
  try {
    yield put({ type: "START_GET_TEMPLATE", action });

    const template = yield call(api.getTemplate, action.payload);

    yield put(selectTemplate(template));
    yield put(addNotification({ message: MESSAGES.SUCCESS_GET_TEMPLATE, severity: "success" }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || MESSAGES.ERROR_GET_TEMPLATE, severity: "error" }));
    yield put({ type: "ERROR_GET_TEMPLATE", error });
  }
}

export function* watchSelectCurrentTemplate() {
  yield takeEvery(REPORT_TEMPLATES_GET_TEMPLATE_SAGA, getTemplate);
}
