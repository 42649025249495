// @flow
import React from "react";
import {
  AppBar, Box, IconButton, Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MassCostCorrectionHeader = (
  { count, onClose }: { count: number, onClose: () => void }
) => (
  <AppBar position="relative" color="primary">
    <Box p={3} display="flex">
      <Box flexGrow={1}>
        <Typography variant="body1" color="inherit">EDIT TRANSACTION</Typography>
        <Typography variant="subtitle2">
          Count transaction:
          {" "}
          {count}
        </Typography>
      </Box>
      <Box>
        <IconButton onClick={onClose} color="inherit" size="large">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  </AppBar>
);

export default MassCostCorrectionHeader;
