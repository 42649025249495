import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { REPORT_TEMPLATE_BAKE_REPORT_SAGA } from "../reportTemplates.actions-types";
import {
  clearOldReportData,
  errorFetchReport,
  startFetchReport,
  successBakeReport,
} from "../reportTemplates.actions";
import { MESSAGES } from "../reportTemplates.constants";

export function* bakeReport() {
  try {
    yield put(clearOldReportData());
    yield put(startFetchReport());

    const template = yield select((state) => state.reportTemplates.current);

    const report = yield call(api.bakeReport, template);

    yield put(successBakeReport(report));
    yield put(addNotification({ message: MESSAGES.SUCCESS_BAKE_REPORT, severity: "success" }));
  }
  catch (error) {
    yield put(errorFetchReport());
    yield put(addNotification({ message: error.errorMessage || MESSAGES.ERROR_BAKE_REPORT, severity: "error" }));
  }
}

export function* watchBakeReport() {
  yield takeEvery(REPORT_TEMPLATE_BAKE_REPORT_SAGA, bakeReport);
}
