// @flow
import React from "react";
import {
  Box, Button, Paper, Typography,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

interface Props {
  action?: void,
  text: string,
}

const ApproveDialog = (props: Props) => {
  const { action, text } = props;
  return (
    <Paper elevation={0}>
      <Box component="div" p={3} textAlign="center">
        <Typography component="p">
          {text}
        </Typography>
        {action && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<MailOutlineIcon />}
            onClick={action}
            data-test-id="bake-report"
          >
            Bake report
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default ApproveDialog;
