// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, useState } from "react";
import { Divider, Dialog } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { isEqual } from "lodash";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import CostCorrectionHeader from "./CostCorrectionHeader";
import ViewTransactionData from "./ViewTransactionData";
import TransactionDataForm from "./TransactionDataForm";
import CostCorrectionFooter from "./CostCorrectionFooter";
import type { TransactionData } from "../../store/costCorrection/types";
import MassCostCorrectionHeader from "./MassCostCorrectionHeader";
import type { UseStateType } from "../../services/utils/utils";

type Props = {
  open: boolean,
  isLoading: boolean,
  transactionData: TransactionData,
  declineReasonValues: Array<string>,
  checkedList: Array<number>,
  modalMode: string,

  onClose: () => void,
  onSave: (transactionData: {
    payout?: number,
    revenue?: number,
    isDeclined: boolean,
    declineReason: Array<string>,
  }) => void,
  isCostCorrectionProcessing: boolean,
}

const DialogModal = withStyles({
  paper: {
    height: "auto",
  },
})(Dialog);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

// eslint-disable-next-line complexity
const CostCorrectionForm = ({
  open,
  isLoading,
  transactionData,
  declineReasonValues,
  onClose,
  onSave,
  modalMode,
  checkedList,
  isCostCorrectionProcessing,
}: Props) => {
  const classes = useStyles();
  const [tData, setTData]: UseStateType<{
    payout: number,
    revenue: number,
    isDeclined: boolean,
    declineReason: Array<string>,
  }> = useState({
    payout: 0,
    revenue: 0,
    isDeclined: false,
    declineReason: [],
  });
  const checkedCounter: number = checkedList.length;

  useEffect(() => {
    if (checkedCounter === 1) {
      setTData({
        payout: transactionData.payout,
        revenue: transactionData.revenue,
        isDeclined: transactionData.isDeclined,
        declineReason: (transactionData.declineReason || [])
          .filter((item: string) => declineReasonValues.some(((value) => item === value))),
      });
    }
  }, [declineReasonValues, transactionData, checkedCounter]);

  const range = {
    min: 0,
    max: 99999,
  };

  const {
    payout,
    revenue,
    isDeclined,
    declineReason = [],
  } = tData;

  const isErrorPayout = !(range.min <= payout && payout <= range.max);
  const isErrorRevenue = !(range.min <= revenue && revenue <= range.max);
  const isErrorDeclineReason = isDeclined === true && (declineReason.length === 0);
  const isEqualData = isEqual(tData, transactionData);

  const handleChangePayout = modalMode === "correctionRevenue"
    ? null : (v) => setTData({ ...tData, payout: v });
  const handleChangeRevenue = modalMode === "correctionPayout"
    ? null : (v) => setTData({ ...tData, revenue: v });

  const handleApplyCorrection = () => {
    const dataForCorrection: {
      payout?: number,
      revenue?: number,
      isDeclined: boolean,
      declineReason: Array<string>,
    } = {
      revenue: tData.revenue,
      payout: tData.payout,
      isDeclined: tData.isDeclined,
      declineReason: tData.declineReason,
    };
    if (modalMode === "correctionPayout") {
      delete dataForCorrection.revenue;
    }
    if (modalMode === "correctionRevenue") {
      delete dataForCorrection.payout;
    }

    onSave(dataForCorrection);
  };

  return (
    <>
      <DialogModal
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
        data-testid="correctionModal"
      >
        {checkedCounter > 1 && (
          <MassCostCorrectionHeader count={checkedCounter} onClose={onClose} />
        )}
        {checkedCounter === 1 && (
          <CostCorrectionHeader transactionData={transactionData} onClose={onClose} />
        )}
        <Divider />
        {checkedCounter === 1 && (
          <ViewTransactionData transactionData={transactionData} />
        )}
        <Divider />
        <TransactionDataForm
          transactionData={tData}
          onChangeIsDeclined={
            (v) => setTData({ ...tData, isDeclined: v, declineReason: v ? declineReason : [] })
          }
          onChangePayout={handleChangePayout}
          onChangeReason={(v) => setTData({ ...tData, declineReason: v })}
          onChangeRevenue={handleChangeRevenue}
          declineReasonValues={declineReasonValues}
          isErrorPayout={isErrorPayout}
          isErrorRevenue={isErrorRevenue}
          isErrorDeclineReason={isErrorDeclineReason}
        />
        <Divider />
        <CostCorrectionFooter
          onApply={handleApplyCorrection}
          disabled={isErrorPayout || isErrorRevenue
            || isErrorDeclineReason || isEqualData
            || isCostCorrectionProcessing}
        />
      </DialogModal>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CostCorrectionForm;
