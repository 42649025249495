// @flow
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import api from "../../../services/api";
import { MESSAGES } from "../../reportTemplates/reportTemplates.constants";
import { COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA } from "../actions-types";
import { closeModal, setIsCostCorrectionProcessing } from "../actions";
import type { UpdateTransactionDataSagaAction } from "../actions";
import type { Filters, Filter } from "../../reportTemplates/types";
import type { CommonState } from "../../preloadState";

export function* updateCostCorrection(
  action: UpdateTransactionDataSagaAction
): Saga<void> {
  const dimensions: Array<{tds_cid?: Filter<string>, conversion_date?: Filter<string>}> = [];
  const tableData: Array<Filters> = yield select((state: CommonState) => state.reportTemplates.tableData);
  const checkedList: Array<number> = yield select((state: CommonState) => state.reportTemplates.checkedList);
  checkedList.forEach((index: number) => {
    dimensions.push({
      tds_cid: tableData[index].tds_cid,
      conversion_date: tableData[index].conversion_date_from,
    });
  });
  try {
    yield put(setIsCostCorrectionProcessing(true));
    yield call(api.updateTransactionData, { ...action.value, dimensions });
    yield put(setIsCostCorrectionProcessing(false));

    yield put(closeModal());

    yield put(addNotification({ message: MESSAGES.SUCCESS_UPDATE_TRANSACTION_DATA, severity: "success" }));
  }
  catch (error) {
    yield put(setIsCostCorrectionProcessing(false));
    yield put(addNotification({
      message: error.errorMessage || MESSAGES.ERROR_UPDATE_TRANSACTION_DATA,
      severity: "error",
    }));
  }
}

export function* watchUpdateCostCorrection(): Saga<void> {
  yield takeEvery(
    COST_CORRECTION_UPDATE_TRANSACTION_DATA_SAGA,
    updateCostCorrection
  );
}
