// @flow
import type { Filters, Params } from "../types";
import Filter from "../SelectionGroupFactory/Filter.types";
import ReportFilterGroup from "./ReportFilterGroup";
import type {
  FiltersModel, GroupByPreset, Hints, ReportConfig, Toggle,
} from "./types/index.types";

/**
 * Report is a base class for report model
 * @namespace ReportModel
 */
export default class Report {
  filters: FiltersModel;

  filterGroups: ReportFilterGroup;

  requiredGroupToExecute: string[][];

  toggles: Toggle[];

  groupByList: GroupByPreset[];

  hints: Hints;

  constructor({
    filterGroupsConfig,
    toggles = [],
    groupByList = [],
    requiredGroupToExecute = [],
    hints = {},
  }: ReportConfig) {
    this.filterGroups = new ReportFilterGroup(filterGroupsConfig);
    this.filters = this.filterGroups.getFilters();
    this.requiredGroupToExecute = requiredGroupToExecute;
    this.toggles = toggles;
    this.groupByList = groupByList;
    this.hints = hints;
  }

  canExecute(params: Params, selectedFilters: Filters): boolean {
    const selectedGroupsTitle = this.filterGroups.getGroupTitlesByFilters(selectedFilters);
    return this.requiredGroupToExecute
      .every((someGroup) => someGroup.some((group) => selectedGroupsTitle.includes(group)));
  }

  getFilters(): FiltersModel {
    return this.filters;
  }

  getFilterByKey(filterKey: string): Filter {
    if (this.filters[filterKey]) {
      return this.filters[filterKey];
    }
    this.filters[filterKey] = new Filter();
    this.filters[filterKey].filterKey = filterKey;
    return this.filters[filterKey];
  }

  getGroupByList(): GroupByPreset[] {
    return this.groupByList;
  }

  getToggles(): Toggle[] {
    return this.toggles;
  }

  getFilterGroups(): ReportFilterGroup {
    return this.filterGroups;
  }

  getAllowedFilterKeys(params: Params): string[] {
    return Object.keys(this.filters).filter((filterKey) => this.getFilterByKey(filterKey).value(params));
  }

  getHints(): Hints {
    return this.hints;
  }
}
