// @flow
import _ from "lodash";

import type { State } from "./types";
import {
  REPORT_TEMPLATE_DELETE_FILTER,
  REPORT_TEMPLATE_REORDER,
  REPORT_TEMPLATE_CLEAR_FILTERS,
  REPORT_TEMPLATES_ADD_FILER,
  REPORT_TEMPLATES_ADD_TEMPLATE,
  REPORT_TEMPLATES_TOGGLE_FILTERS,
  REPORT_TEMPLATE_CHANGE_ACTIVE_FIELDS,
  REPORT_TEMPLATE_RENAME,
  REPORT_TEMPLATE_SET_DATE_RANGE,
  REPORT_TEMPLATE_SET_TEMPLATE_NAME,
  SET_TEMPLATES_LIST,
  REPORT_TEMPLATES_SELECT_TEMPLATE,
  WARN_SHOW_REPORT,
  ERROR_FETCH_REPORT,
  START_FETCH_REPORT,
  SUCCESS_SHOW_REPORT,
  SUCCESS_BAKE_REPORT,
  REPORT_TEMPLATE_SET_PERIOD,
  CLEAR_OLD_REPORT_DATA,
  REPORT_TEMPLATES_TOGGLE_TDS_CID,
  CHANGE_ACTIVE_RULE, REPORT_TEMPLATE_CHANGE_ORDER,
  REPORT_TEMPLATE_CHANGE_CURRENT_DAY,
  SET_TEMPLATE_GROUP_BY,
  REPORT_TEMPLATES_SET_HIDDEN_FILTER,
  CHANGE_CHECKED_LIST,
  CHANGE_TABLE_DATA,
} from "./reportTemplates.actions-types";
import { RESET_EXCLUDE_FILTERS, PRESETS } from "./reportTemplates.constants";
import {
  getNewTemplate,
} from "./reportTemplates.utils";
import {
  selectCurrent,
  selectCurrentFilters,
  selectCurrentFiltersWithout,
} from "./reportTemplates.selectors";
import initState from "./reportTemplates.initState";

function addTemplate(state: State) {
  const template = getNewTemplate(state.reportType);

  return {
    ...state,
    current: template,
  };
}

function setCurrentTemplate(state, action) {
  const { filters, params } = action.payload;
  const dateKey = params.tdsCid ? "conversion_date" : "date";

  if (params.period) {
    const preset = PRESETS[params.period] || (params.currentDay ? PRESETS.today : PRESETS.yesterday);
    filters[dateKey] = [{ from: preset.start, to: preset.end }];
  }

  if (!params.groupBy) {
    // eslint-disable-next-line no-param-reassign
    params.groupBy = "day";
  }

  return {
    ...state,
    current: {
      ...action.payload,
      filters,
      params: {
        ...params,
        groupBy: params.groupBy || "day",
      },
    },
  };
}

/* eslint-disable complexity */
export default function reportTemplatesReducer(state: State = initState, action: any) {
  switch (action.type) {
    case CHANGE_CHECKED_LIST:
      return {
        ...state,
        checkedList: action.value,
      };
    case REPORT_TEMPLATES_ADD_TEMPLATE:
      return addTemplate(state);

    case START_FETCH_REPORT:
      return {
        ...state,
        isBaking: false,
        isFetchingData: true,
      };

    case SUCCESS_BAKE_REPORT:
      return {
        ...state,
        isFetchingData: false,
        tableData: [],
        tableDataTotal: [],
      };

    case SUCCESS_SHOW_REPORT:
      return {
        ...state,
        tableData: action.payload.data,
        tableDataTotal: action.payload.total,
        isFetchingData: false,
        isBaking: action.payload.retryInBakery,
      };

    case ERROR_FETCH_REPORT: {
      return {
        ...state,
        isFetchingData: false,
        isBaking: action.payload?.isBaking,
      };
    }

    case WARN_SHOW_REPORT:
      return {
        ...state,
        isBaking: true,
        isFetchingData: false,
      };

    case REPORT_TEMPLATE_SET_PERIOD:
      return {
        ...state,
        current: {
          ...state.current,
          params: {
            ...state.current.params,
            period: action.payload,
          },
        },
      };

    case REPORT_TEMPLATES_SELECT_TEMPLATE:
      return setCurrentTemplate(state, action);

    case REPORT_TEMPLATES_TOGGLE_FILTERS: {
      const { filters } = action;

      return {
        ...state,
        current: {
          ...state.current,
          filters,
        },
      };
    }

    case REPORT_TEMPLATES_SET_HIDDEN_FILTER: {
      const fieldsHidden = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          fieldsHidden,
        },
      };
    }

    case REPORT_TEMPLATE_CHANGE_ORDER: {
      const { fieldsOrder } = action;
      return {
        ...state,
        current: {
          ...state.current,
          fieldsOrder,
        },
      };
    }

    case REPORT_TEMPLATE_CHANGE_ACTIVE_FIELDS: {
      return {
        ...state,
        current: {
          ...state.current,
        },
      };
    }

    case REPORT_TEMPLATE_REORDER: {
      const { source, destination } = action.payload;
      if (!destination) {
        return state;
      }
      const oldIndex = source.index + 1;
      const newIndex = destination.index + 1;
      const current = selectCurrent(state);
      const { fieldsOrder } = current;
      const { [oldIndex]: moved } = fieldsOrder;
      const rest = fieldsOrder.filter((v, index) => index !== oldIndex);

      return {
        ...state,
        current: {
          ...current,
          fieldsOrder: [
            ...rest.slice(0, newIndex),
            moved,
            ...rest.slice(newIndex),
          ],
        },
      };
    }

    case REPORT_TEMPLATES_ADD_FILER: {
      const current = selectCurrent(state);
      const filters = selectCurrentFilters(state);
      const { key, value } : { key: string, value: string[] } = action.payload;
      const filteredValues: string[] = [...new Set(value.filter((v: string) => !filters[key].includes(v)))];

      if (!filteredValues.length) return state;

      return {
        ...state,
        current: {
          ...current,
          filters: {
            ...filters,
            [key]: [...filters[key], ...filteredValues], // FIXME: trimmedValue
          },
        },
      };
    }

    case REPORT_TEMPLATE_DELETE_FILTER: {
      const { key, value } = action.payload;
      const current = selectCurrent(state);
      const filters = selectCurrentFilters(state);

      return {
        ...state,
        current: {
          ...current,
          filters: {
            ...filters,
            [key]: _.without(filters[key], value),
          },
        },
      };
    }

    case REPORT_TEMPLATE_CLEAR_FILTERS: {
      const current = selectCurrent(state);
      const filters = selectCurrentFilters(state);
      const filtersWithoutDate = selectCurrentFiltersWithout(RESET_EXCLUDE_FILTERS)(state);
      const clearedFilters = (_.reduce(
        filtersWithoutDate,
        (accumulator, item, key: string) => ({ ...accumulator, [key]: [] }),
        {}
      ): any);

      return {
        ...state,
        current: {
          ...current,
          filters: {
            ...filters,
            ...clearedFilters,
          },
        },
      };
    }

    case REPORT_TEMPLATE_RENAME: {
      const current = selectCurrent(state);

      return { ...state, current: { ...current, name: action.payload } };
    }

    case REPORT_TEMPLATE_SET_DATE_RANGE: {
      const { range, fieldKey } = action;
      return {
        ...state,
        current: {
          ...state.current,
          filters: {
            ...state.current,
            [fieldKey]: [range],
          },
        },
      };
    }

    case REPORT_TEMPLATE_SET_TEMPLATE_NAME: {
      return {
        ...state,
        current: {
          ...state.current,
          name: action.payload,
        },
      };
    }

    case SET_TEMPLATES_LIST: {
      const { list } = action;
      return {
        ...state,
        templatesList: list,
      };
    }

    case CLEAR_OLD_REPORT_DATA: {
      return {
        ...state,
        tableData: [],
        tableDataTotal: [],
      };
    }

    case REPORT_TEMPLATES_TOGGLE_TDS_CID: {
      const { value } = action;
      return {
        ...state,
        current: {
          ...state.current,
          params: {
            ...state.current.params,
            tdsCid: value,
          },
        },
      };
    }

    case CHANGE_ACTIVE_RULE: {
      const { value, name } = action;

      return {
        ...state,
        current: {
          ...state.current,
          filters: {
            ...state.current.filters,
            [name]: value,
          },
        },
      };
    }

    case REPORT_TEMPLATE_CHANGE_CURRENT_DAY: {
      const { value } = action;
      return {
        ...state,
        current: {
          ...state.current,
          params: {
            ...state.current.params,
            currentDay: value,
          },
        },
      };
    }

    case SET_TEMPLATE_GROUP_BY: {
      const { value } = action;
      return {
        ...state,
        current: {
          ...state.current,
          params: {
            ...state.current.params,
            groupBy: value,
          },
        },
      };
    }
    case CHANGE_TABLE_DATA: {
      return {
        ...state,
        tableData: action.value,
      };
    }

    default:
      return state;
  }
}
