// @flow

export const REPORT_TEMPLATES_ADD_TEMPLATE = "REPORT_TEMPLATES_ADD_TEMPLATE";
export const REPORT_TEMPLATES_TOGGLE_TDS_CID = "REPORT_TEMPLATES_TOGGLE_TDS_CID";
export const REPORT_TEMPLATES_ADD_FILER = "REPORT_TEMPLATES_ADD_FILER";
export const REPORT_TEMPLATES_TOGGLE_TODAY = "REPORT_TEMPLATES_TOGGLE_TODAY";
export const REPORT_TEMPLATES_TOGGLE_FILTERS = "REPORT_TEMPLATES_TOGGLE_FILTERS";
export const REPORT_TEMPLATE_REORDER = "REPORT_TEMPLATE_REORDER";
export const REPORT_TEMPLATE_DELETE_FILTER = "REPORT_TEMPLATE_DELETE_FILTER";
export const REPORT_TEMPLATE_CLEAR_FILTERS = "REPORT_TEMPLATE_CLEAR_FILTERS";
export const REPORT_TEMPLATE_RENAME = "REPORT_TEMPLATE_RENAME";
export const REPORT_TEMPLATE_SET_DATE_RANGE = "REPORT_TEMPLATE_SET_DATE_RANGE";
export const REPORT_TEMPLATE_SET_TEMPLATE_NAME = "REPORT_TEMPLATE_SET_TEMPLATE_NAME";
export const REPORT_TEMPLATES_SELECT_TEMPLATE = "REPORT_TEMPLATES_SELECT_TEMPLATE";
export const REPORT_TEMPLATE_SAVE_SAGA = "REPORT_TEMPLATE_SAVE_SAGA";
export const REPORT_TEMPLATE_DELETE_SAGA = "REPORT_TEMPLATE_DELETE_SAGA";
export const REPORT_TEMPLATE_BAKE_REPORT_SAGA = "REPORT_TEMPLATE_BAKE_REPORT_SAGA";
export const REPORT_TEMPLATE_SHOW_REPORT_SAGA = "REPORT_TEMPLATE_SHOW_REPORT_SAGA";
export const REPORT_TEMPLATES_GET_TEMPLATE_SAGA = "REPORT_TEMPLATES_GET_TEMPLATE_SAGA";
export const START_FETCH_REPORT = "START_FETCH_REPORT";
export const ERROR_FETCH_REPORT = "ERROR_FETCH_REPORT";
export const WARN_SHOW_REPORT = "WARN_SHOW_REPORT";
export const SUCCESS_SHOW_REPORT = "SUCCESS_SHOW_REPORT";
export const SUCCESS_BAKE_REPORT = "SUCCESS_BAKE_REPORT";
export const REPORT_TEMPLATE_SET_PERIOD = "REPORT_TEMPLATE_SET_PERIOD";
export const UPDATE_TEMPLATES_LIST_SAGA = "UPDATE_TEMPLATES_LIST_SAGA";
export const SET_TEMPLATES_LIST = "SET_TEMPLATES_LIST";
export const START_SAVE_TEMPLATE = "START_SAVE_TEMPLATE";
export const SUCCESS_SAVE_TEMPLATE = "SUCCESS_SAVE_TEMPLATE";
export const ERROR_SAVE_TEMPLATE = "ERROR_SAVE_TEMPLATE";
export const CLEAR_OLD_REPORT_DATA = "CLEAR_OLD_REPORT_DATA";
export const REPORT_TEMPLATE_TOGGLE_SWITCHER_SAGA = "REPORT_TEMPLATE_TOGGLE_SWITCHER_SAGA";
export const CHANGE_ACTIVE_RULE = "CHANGE_ACTIVE_RULE";
export const REPORT_TEMPLATE_TOGGLE_FILTER_SWITCHER_SAGA = "REPORT_TEMPLATE_TOGGLE_FILTER_SWITCHER_SAGA";
export const REPORT_TEMPLATE_CHANGE_ORDER = "REPORT_TEMPLATE_CHANGE_ORDER";
export const REPORT_TEMPLATE_CHANGE_ACTIVE_FIELDS = "REPORT_TEMPLATE_CHANGE_ACTIVE_FIELDS";
export const REPORT_TEMPLATE_TOGGLE_FILTER_ALL_SAGA = "REPORT_TEMPLATE_TOGGLE_FILTER_ALL_SAGA";
export const REPORT_TEMPLATE_CHANGE_CURRENT_DAY = "REPORT_TEMPLATE_CHANGE_CURRENT_DAY";
export const CHANGE_CALENDAR_DATE_SAGA = "CHANGE_CALENDAR_DATE_SAGA";
export const SET_TEMPLATE_GROUP_BY = "SET_TEMPLATE_GROUP_BY";
export const SET_TEMPLATE_GROUP_BY_SAGA = "SET_TEMPLATE_GROUP_BY_SAGA";
export const REPORT_TEMPLATES_SET_HIDDEN_FILTER = "REPORT_TEMPLATES_SET_HIDDEN_FILTER";
export const CHANGE_CHECKED_LIST = "CHANGE_CHECKED_LIST";
export const CHANGE_TABLE_DATA = "CHANGE_TABLE_DATA";
