import { put, select, takeEvery } from "redux-saga/effects";

import dayjs from "dayjs";
import { setTemplateGroupBy } from "../reportTemplates.actions";
import { SET_TEMPLATE_GROUP_BY_SAGA } from "../reportTemplates.actions-types";
import { getPresetByPeriod } from "../reportTemplates.constants";
import { selectCurrent, selectCurrentReportModel } from "../reportTemplates.selectors";
import { changeCalendarDate } from "./changeCalendarDateSaga";

export function* setTemplateGroupBySaga(params) {
  const current = yield select((state) => selectCurrent(state.reportTemplates));
  const report = yield select((state) => selectCurrentReportModel(state.reportTemplates));
  const { period } = current.params;

  const { value } = params;
  const { presets } = report.getGroupByList().find((groupByItem) => groupByItem.value === value) || {};

  if (!presets) {
    return;
  }
  yield put(setTemplateGroupBy(value));
  const preset = getPresetByPeriod(presets, period);
  yield* changeCalendarDate({
    range: {
      from: dayjs(preset.start).format("YYYY-MM-DD"),
      to: dayjs(preset.end).format("YYYY-MM-DD"),
    },
    preset: preset.value,
  });
}

export default setTemplateGroupBySaga;

export function* watchSetTemplateGroupBy() {
  yield takeEvery(SET_TEMPLATE_GROUP_BY_SAGA, setTemplateGroupBySaga);
}
