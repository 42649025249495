import { put, select, takeEvery } from "redux-saga/effects";
import _, { omit } from "lodash";
import { REPORT_TEMPLATE_TOGGLE_FILTER_SWITCHER_SAGA } from "../reportTemplates.actions-types";
import { selectCurrent } from "../reportTemplates.selectors";
import {
  toggleFilters,
  changeOrder,
  setHiddenFilters,
} from "../reportTemplates.actions";

export function* toggleFilterSaga(action) {
  const current = yield select((state) => selectCurrent(state.reportTemplates));
  let {
    fieldsOrder, filters,
  } = _.cloneDeep(current);
  const { fieldsHidden } = _.cloneDeep(current);

  const { filters: changedFilters, value } = action;

  changedFilters.forEach((key) => {
    if (value) {
      if (!fieldsOrder.includes(key)) {
        fieldsOrder.push(key);
        filters[key] = [];
      }
    }
    if (!value) {
      filters = omit(filters, key);
      fieldsOrder = fieldsOrder.filter((x) => x !== key);
    }
  });

  const newHiddenFields = fieldsHidden.filter((x) => filters[x]);

  yield put(toggleFilters(filters));
  yield put(changeOrder(fieldsOrder));
  yield put(setHiddenFilters(newHiddenFields));
}

export function* watchToggleFilterSaga() {
  yield takeEvery(REPORT_TEMPLATE_TOGGLE_FILTER_SWITCHER_SAGA, toggleFilterSaga);
}
