// @flow
import dayjs from "dayjs";
import { GROUP_BY_LIST, PRESETS } from "../reportTemplates.constants";
import type { ReportConfig } from "./types/index.types";

/**
 * Configuration for manager reports
 */
export default ({
  filterGroupsConfig: {
    "Date": [
      "Date",
      "ConversionDate",
      "ConversionTime",
    ],
    "SmartLink Data": [
      "SmartLinkId",
      "SmartLinkName",
      "SmartLinkSplitFlag",
      "SmartLinkFunnelName",
      "SmartLinkFilter",
      "EntryReason",
    ],
    "Basic Data": [
      "BasicId",
      "BasicName",
      "ReturnReason",
      "BasicSplitFlag",
      "BasicFunnelName",
      "BasicFilter",
      "CostType",
    ],
    "User info": [
      "Country",
      "Region",
      "Platform",
      "DeviceType",
      "DeviceBrand",
      "DeviceModel",
      "DeviceOS",
      "DeviceOEM",
      "BrowserLanguage",
      "Carrier",
      "ConnectionType",
      "RiskScore",
      "TdsCid",
      "Data2",
      "Ip",
      "Age",
      "EmailDomain",
      "Pid",
      "Browser",
    ],
    "Utm Params": [
      "UtmCampaign",
      "UtmCampaignName",
      "UtmCampaignManager",
      "UtmContent",
      "UtmTerm",
      "UtmSource",
      "IsPopunder",
      "AdNetwork",
      "S1",
      "S3",
      "S5",
      "ConversionType",
      "Currency",
      "P1",
      "P2",
      "P3",
      "P4",
      "P5",
      "P6",
      "P7",
      "P9",
      "P10",
      "S2",
    ],
    "Data": [
      "JumpName",
      "JumpId",
      "Brand",
      "Site",
      "LP",
      "Domain",
      "Account",
      "Partner",
      "LPTail",
      "DeclineReason",
      "DeclineDate",
      "CpaOfferID",
      "CpaOfferName",
      "PartnerOfferName",
      "LinkLocation",
      "CpaOfferConversionType",
      "Department",
      "ManagerNameCPAOffer",
      "Vertical",
      "ExtNiche",
      "IsExt",
      "IsActive",
      "IsValidOffers",
      "ProjectName",
      "AdvertiserManager",
      "PaymentModel",
      "ExtOfferGroup",
      "MonetizationType",
      "Stream",
      "OfferCampaignID",
      "GlobalCap",
      "PartnerCommission",
      "DefaultCommission",
      "Postbacks",
      "IsDeclined",
      "IsShaved",
      "IsTarget",
      "IsBoosted",
      "SiteProvider",
      "FunnelProvider",
      "PassedGF",
      "PassesGFDryMode",
      "ParentTDSCampaign",
      "ParentSplit",
      "ParentJump",
      "TdsBOCounter",
      "TdsSOCounter",
      "TdsReasonCounter",
    ],
    "Tracking": [
      "Entries",
      "Hits",
      "Visits",
      "BrandClicks",
      "Clicks",
      "AltClicks",
      "Fallbacks",
      "BaseCid",
      "OriginalConversions",
      "Conversions",
      "AltConversions",
      "Installs",
      "RegistrationsInApp",
      "Registrations",
      "CPAExtRegistrations",
      "CPAExtPayments",
      "CPAClicks",
      "CPAConfirms",
      "CPAInstalls",
      "CPAPayments",
      "CPARegistrations",
      "CostForDecline",
      "Costs",
      "OriginalPayout",
      "CostInCurrency",
      "Payout",
      "PayoutExternalOffers",
      "PayoutTNOffers",
      "Revenue",
      "OriginalRevenue",
      "CPMRevenue",
      "RevenueInCurrency",
      "TNRevenue",
      "PartnerRevenue",
      "AltPartnerRevenue",
      "AltDefRevenue",
      "AltRevenue",
      "PartnerRevenueInCurrency",
      "Cards1D",
      "OriginalLeads",
      "Leads",
      "BOClicks",
      "SOClicks",
      "Impressions",
    ],
    "Metrics": [
      "EpcTNRevenueClick",
      "EPV",
      "CR",
      "JumpCTR",
      "BannerCTR",
      "Hit2Lead",
      "Hit2Conv",
      "Hit2Reg",
      "Visit2Lead",
      "Visit2Conv",
      "Visit2Reg",
      "Click2Lead",
      "Click2Reg",
      "ROI",
      "Profit",
      "CPL",
      "Rate",
      "PartnerCPReg",
      "PartnerCPSale",
      "AltEpcClick2Cost",
      "AltEpcClick2Revenue",
      "AltEpcClick2RevenueWithCpm",
      "AltEpv",
      "AltCr",
      "AltJumpCtr",
      "AltCpl",
      "AltRate",
      "AltRoi",
      "AltProfit",
      "EPCCostClick",
      "EPCRevenueClick",
      "EPCRevenueCPMRevenueClick",
      "NCR",
      "RPT",
      "EPreg",
    ],
  },
  toggles: [{
    label: "Extra Conversion Data",
    value: "tdsCid",
    hint: "When enabled, you can see the statistics for the specific tds_cid, user info and decline attribute.",
  }],
  groupByList: [
    {
      label: "Group by Hour",
      value: GROUP_BY_LIST.hour,
      presets: [
        PRESETS.yesterday,
        PRESETS.today,
        PRESETS.last3days,
        PRESETS.thisweek,
      ],
      range: {
        min: dayjs().startOf("d").subtract(7, "d").toISOString(),
        max: dayjs().endOf("d").toISOString(),
      },
    },
    {
      label: "Group by Day",
      value: GROUP_BY_LIST.day,
      presets: [
        PRESETS.yesterday,
        PRESETS.today,
        PRESETS.lastweek,
        PRESETS.thisweek,
        PRESETS.thismonth,
      ],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
    {
      label: "Group by Week",
      value: GROUP_BY_LIST.week,
      presets: [
        PRESETS.lastweek,
        PRESETS.thisweek,
      ],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
    {
      label: "Group by Month",
      value: GROUP_BY_LIST.month,
      presets: [
        PRESETS.lastmonth,
        PRESETS.thismonth,
      ],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
    {
      label: "Group by Year",
      value: GROUP_BY_LIST.year,
      presets: [PRESETS.thisyear],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
  ],
  requiredGroupToExecute: [
    ["Date"],
    ["Tracking", "Metrics"],
    ["SmartLink Data", "Basic Data", "User info", "Utm Params", "Data"],
  ],
  hints: {
    groupBy: "When selected Group by Hour, you can see hourly statistics, however some attributes are unavailable.",
    datePicker: "When selected Today or Custom Range, you can see statistics for the period, including current date, however some attributes are unavailable.",
  },
}: ReportConfig);
