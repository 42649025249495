// @flow
import type { State } from "./types";

const initState: State = {
  // $FlowFixMe
  current: null,
  templateName: "",
  templatesList: [],
  tableData: [],
  tableDataTotal: [],
  isFetchingData: false,
  isBaking: false,
  reportType: "manager",
  checkedList: [],
};

export default initState;
