// @flow
import dayjs from "dayjs";
import { GROUP_BY_LIST, PRESETS } from "../reportTemplates.constants";
import type { ReportConfig } from "./types/index.types";

/**
 * Configuration for accountant reports
 */
export default ({
  filterGroupsConfig: {
    "Date": [
      "Date",
      "FirstConversionDate",
      "CreatedFirst",
      "CreatedLast",
    ],
    "Utm Params": [
      "IDAff",
      "AffiliateType",
      "PayoutCompany",
      "Name",
      "Manager",
      "AffiliateStatus",
      "PaymentMethod",
      "BeneficiaryName",
      "Wallet",
      "BeneficiaryAddress",
      "BeneficiaryCountry",
      "BankName",
      "BankAddress",
      "PayoutCountry",
    ],

    "Tracking": [
      "Cost",
      "ExtCost",
      "IntCost",
      "Paid",
      "ExtPaid",
      "IntPaid",
      "Declined",
      "NotPaid",
      "Adjustments",
    ],

  },
  toggles: [],
  groupByList: [
    {
      label: "Group by Day",
      value: GROUP_BY_LIST.day,
      presets: [
        PRESETS.yesterday,
        PRESETS.today,
        PRESETS.lastweek,
        PRESETS.thisweek,
        PRESETS.thismonth,
        PRESETS.from2020,
      ],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
    {
      label: "Group by Week",
      value: GROUP_BY_LIST.week,
      presets: [
        PRESETS.lastweek,
        PRESETS.thisweek,
        PRESETS.from2020,
      ],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
    {
      label: "Group by Month",
      value: GROUP_BY_LIST.month,
      presets: [
        PRESETS.lastmonth,
        PRESETS.thismonth,
        PRESETS.from2020,
      ],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
    {
      label: "Group by Year",
      value: GROUP_BY_LIST.year,
      presets: [
        PRESETS.thisyear,
        PRESETS.from2020,
      ],
      range: {
        min: undefined,
        max: dayjs().endOf("d").toISOString(),
      },
    },
  ],
  requiredGroupToExecute: [
    ["Date"],
    ["Tracking"],
    ["Utm Params"],
  ],
  hints: {
    datePicker: "When selected Today or Custom Range, you can see statistics for the period, including current date, however some attributes are unavailable.",
  },
}: ReportConfig);
