/* eslint-disable import/max-dependencies */
import {
  all,
} from "redux-saga/effects";
import { watchUpdateTemplateList } from "./updateTemplateList";
import { watchCreateTemplate } from "./saveTemplate";
import { watchDeleteTemplate } from "./deleteTemplate";
import { watchShowReport } from "./showReport";
import { watchSelectCurrentTemplate } from "./getTemplate";
import { watchBakeReport } from "./bakeReport";
import { watchUpdateCostCorrection } from "../../costCorrection/saga/updateTransactioinDataSaga";
import { watchOpenCostCorrection } from "../../costCorrection/saga/openModalSaga";
import { watchToggleAllFilters } from "./toggleFilterAllSaga";
import { watchToggleSwitcherSaga } from "./toggleSwitchersSaga";
import { watchToggleFilterSaga } from "./toggleFilterSaga";
import { watchChangeCalendarDate } from "./changeCalendarDateSaga";
import { watchSetTemplateGroupBy } from "./setTemplateGroupBy";

export default function* mainSaga() {
  yield all([
    watchUpdateCostCorrection(),
    watchOpenCostCorrection(),
    watchUpdateTemplateList(),
    watchCreateTemplate(),
    watchDeleteTemplate(),
    watchShowReport(),
    watchSelectCurrentTemplate(),
    watchBakeReport(),
    watchToggleFilterSaga(),
    watchToggleAllFilters(),
    watchToggleSwitcherSaga(),
    watchChangeCalendarDate(),
    watchSetTemplateGroupBy(),
  ]);
}
